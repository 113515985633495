export default {
  SEARCHNO: 'Cari Nomor Kendaraan',
  DB_TRANSPORT: 'Database Kendaraan',
  ADD_TRANSPORT: 'Tambah Kendaraan',
  CSV_UPLOAD: 'Upload CSV',
  CSV_TEMP: 'Template CSV',
  STATUS_UPLOAD: 'Status data yang sudah diupload',
  FOTO: 'Foto',
  TRANSPORT_NAME: 'Kendaraan',
  TRANSPORT_NUMBER: 'No. Kendaraan',
  DRIVER_DATA: 'Data Sopir',
  SIM_NUMBER: 'No. SIM',
  CREATED_BY: 'Dibuat Oleh',
  IS_ACTIVE: 'Status',
  SUCCESS_MESSAGE_TEXT: 'Data kendaraan berhasil dibuat',
  EDIT_MESSAGE_TEXT: 'Data kendaraan berhasil diubah',
  INPUT_LABEL_TYPE_TRANSPORT: 'Tipe Kendaraan',
  INPUT_LABEL_MERK_TRANSPORT: 'Merk Kendaraan',
  INPUT_LABEL_LICENCE_PLATE: 'Nomor Kendaraan',
  INPUT_LABEL_YEAR_TRANSPORT: 'Tahun',
  INPUT_ALERT_YEAR_TRANSPORT: 'Tahun Kendaraan',
  INPUT_LABEL_KIR_EXPIRED: 'Masa Berlaku KIR',
  INPUT_LABEL_STNK_EXPIRED: 'Masa Berlaku STNK',
  INPUT_LABEL_DRIVER: 'Sopir',
  INPUT_LABEL_IMAGE: 'Gambar',
  INPUT_LABEL_DIMENSION: 'Dimensi',
  EDIT_STATUS_MESSAGE_TEXT: 'Status berhasil diubah',
  DELETE_CONFIRM_TRANSPORT: 'Apakah anda yakin ingin menghapus data kendaraan ini?',
  DELETE_MESSAGE_TEXT_TRANSPORT: 'Data kendaraan berhasil dihapus',
};
