<template>
  <v-app>
    <router-view></router-view>
    <loading ref="loading" />
  </v-app>
</template>

<script>
import Inputmask from 'inputmask';
import loading from './components/Common/loading';
import { setupNotification } from './helper/fcm';

export default {
  name: 'App',

  components: {
    loading,
  },
  data: () => ({
    //
  }),
  computed: {
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    currentPath() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.$root.$loading = this.$refs.loading;
    this.setupNotification();
  },
  methods: {
    async setupNotification() {
      const resultSetupNotification = await setupNotification();
      if (resultSetupNotification) {
        this.$store.dispatch('user/fetchFcmToken');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-footer {
  position: absolute;
  width: 300px;
  margin-top: 10px;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: none;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
</style>
