export default {
  NO_CARGO: 'No Kargo',
  PARTNER_3PL: 'Rekanan 3PL',
  SENDER: 'Nama Pengirim',
  STATUS: 'Status',
  ACTION: 'Aksi',
  DOCUMENT_TYPE: 'Tipe Dokumen',
  DOCUMENT_NAME: 'Nama Dokumen',
  NOTE: 'Catatan',
  HISTORY_NOTE: 'Riwayat Catatan',
  UPLOAD_DOCUMENT: 'Upload Dokumen',
  DELETE_CONFIRM: 'Apakah Anda yakin ingin menghapus data ini?',
  PAGE_NAME: 'Daftar Dokumen Hard Copy POD',
  PAGE_HEADER: 'Dokumen Hard Copy POD',
  PAGE_HEADER_EDIT: 'Dokumen POD',
  COMPLETED_DOCUMENT: 'Dokumen Lengkap',
  INCOMLETE_DOCUMENT: 'Dokumen Lengkap Tidak Lengkap',
  SEND_DOCUMENT: 'Kirim Dokumen Lengkap ke shipper',
  CONFIRM_DELETE_DOCUMENT_TYPE: 'Apakah anda ingin menghapus dokumen ini?',
  DOCUMENT_SUCCESS_MSG_DELETED: 'Data dokumen berhasil dihapus',
  SAVE: 'Simpan',
  CANCEL: 'Batal',
  DOCUMENT_SUCCESS_MSG_EDITED: 'Berhasil mengubah dokumen',
  EDIT: 'Edit Dokumen POD',
  DETAIL: 'Detail Dokumen POD',
  PAGE_TITLE: 'Dokumen POD',
  REMOVE_IMAGE: 'Hapus Foto',
  PICKUP_DATE: 'Tanggal Pengambilan Barang',
  DROP_DATE: 'Tanggal Bongkar',
  DRIVER_NAME: 'Nama Sopir',
  LOKASI: 'Lokasi',
  REVISE: 'Revisi',
  EDIT_DOCUMENT: 'Edit Dokumen POD',
  VIEW_DOCUMENT: 'Detail Dokumen POD',
  NUMBER_ORDER: 'Nomor Pesanan',
};
