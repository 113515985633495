export default {
  headerCatatan: 'Catatan',
  headerNoPesanan: 'No Pesanan',
  headerNamaCustomer: 'Nama Shipper',
  headerRekanan3PL: 'Rekanan 3PL',
  headerKendaraan: 'Kendaraan',
  headerSopir: 'Sopir',
  pageName: 'Daftar Kerusakan dan Selisih',
  searchNo: 'Cari No. Kiriman',
  headerDetail: 'Detail Kerusakan/Selisih',
};
