const state = {
  loading: false,
};

const getters = {
  isLoading: (_state) => _state.loading,
};

const mutations = {
  set_loading(store, isLoading) {
    store.loading = isLoading;
  },
};

const actions = {
  async set_loading({ commit, dispatch }, isLoading) {
    try {
      await commit('set_loading', isLoading);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
