// import roleAccess from '../../assets/strings/roleAccess';
import Vue from 'vue';

const state = {
  myUserInfo: {},
  fcmToken: null,
  isLoading: false,
  inboxUnread: 0,
  waitingOrder: false,
  accidentExists: false,
  userAccess: {},
  menuFunctions: [],
};

const getters = {
  role: (_state) => _state.myUserInfo && _state.myUserInfo.roleId,
  isActivated: (_state) => _state.myUserInfo.isActive,
  isLoading: (_state) => _state.isLoading,
  inboxUnread: (_state) => _state.inboxUnread,
  waitingOrder: (_state) => _state.waitingOrder,
  accidentExists: (_state) => _state.accidentExists,
  userAccess: (_state) => _state.userAccess,
  menuFunctions: (_state) => _state.menuFunctions.reduce((acc, s) => {
    acc[s.accessMenu] = true;
    acc.reports = s.reports || [];
    return acc;
  }, {}),
};

const mutations = {
  set_my_user_info(store, data) {
    store.myUserInfo = data;
  },
  reset_my_user_info(store) {
    store.myUserInfo = null;
  },
  set_fcm_token(store, data) {
    store.fcmToken = data;
  },
  set_loading(store, data) {
    store.isLoading = data;
  },
  set_inbox_unread(store, data) {
    store.inboxUnread = data;
  },
  set_waiting_order(store, data) {
    store.waitingOrder = data;
  },
  set_accident_exists(store, data) {
    store.accidentExists = data;
  },
  set_crud_access(store, data) {
    store.userAccess = data;
  },
  set_menu_functions(store, data) {
    store.menuFunctions = data;
  },
};

const ROLE_ACCESS = [
  'TRANSPORTER_SUPER_ADMIN',
  'TRANSPORTER_ADMIN',
  'TRANSPORTER_ADMIN_FAA',
  'TRANSPORTER_MONITORING',
  'TRANSPORTER_GUEST',
  'TRANSPORTER_GUEST2',
];

const actions = {
  async fetchMyInfo({ commit, getters: _getters }) {
    commit('set_loading', true);
    try {
      const res = await Vue.prototype.$_services.user.myInfo();
      const data = {
        ...res.data,
        rolesName: res.data.roleName.split('_').join().replaceAll(',', ' '),
      };
      commit('set_my_user_info', data);
      commit('set_loading', false);
      if ((!_getters.isActivated && _getters.isActivated !== undefined)
        || (!ROLE_ACCESS.includes(res.data.roleName))) {
        // loadingInstance.close();
        commit('auth/logout', null, { root: true });
        throw new Error('not allowed');
      }
      return res;
    } catch (error) {
      commit('set_loading', false);
      return Promise.reject(error);
    }
  },

  async fetchFcmToken({ commit }) {
    try {
      const fcmToken = await Vue.prototype.$_services.notifications.fetchFcmToken();
      if (fcmToken) {
        commit('set_fcm_token', fcmToken);
        return fcmToken;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },

  setInboxUnread({ commit, dispatch }, data) {
    commit('set_inbox_unread', data);
  },

  setWaitingOrder({ commit }, data) {
    commit('set_waiting_order', data);
  },

  setMyUserInfo({ commit, dispatch }, data) {
    commit('set_my_user_info', data);
  },

  setAccidentExists({ commit, dispatch }, data) {
    commit('set_accident_exists', data);
  },

  setUserAccess({ commit, dispatch }, data) {
    commit('set_crud_access', data);
  },

  setMenuFunctions({ commit, dispatch }, data) {
    commit('set_menu_functions', data);
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
