import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * update common invoice data
 * @param {Object} form invoice common data
 * @param {Object} form.bankAccount bank account details
 * @param {String} form.bankName bank name
 * @param {String} form.bankAccountNumber bank account number
 * @param {String} form.bankAccountName bank account holder name
 * @param {String} form.firstSignerNamesigner 1 name
 * @param {String} form.firstSignerPositionsigner 1 position
 * @param {String} form.secondSignerName signer 2 name
 * @param {String} form.secondSignerPosition signer 2 Position
 * @param {String} form.imageUrl invoice header image url
 * @param {Number} clientId client is
 */
const invoiceUpdateCommonData = async (form) => {
  try {
    // const clientId = 1;
    // const reqData = { ...form,  };
    // const res = await axios.patch(urls.invoiceList, reqData);
    const res = await axios.patch(`${urls.invoiceList}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch client's invoice common data
 * @param {Number} clientId client Id
 */
const invoiceCommonData = async (form) => {
  try {
    const res = await axios.get(`${urls.invoiceList}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  invoiceUpdateCommonData,
  invoiceCommonData,
};
