/* eslint-disable import/no-cycle */
import axios from 'axios';
import Vue from 'vue';
import store from '../../store';

import * as Strings from '../../assets/strings';

export default function setup() {
  axios.interceptors.request.use((config) => {
    const token = store.state.auth.access_token;
    const firebaseToken = store.state.user.fcmToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['firebase-token'] = firebaseToken || '';
    return config;
  }, (err) => Promise.reject(err));

  axios.interceptors.response.use((response) => response,
  // intercept the global error
    (error) => {
      const originalRequest = error.config;
      let urlLastIndex = null;
      if (error.config && error.config.url) {
        urlLastIndex = error.config.url.substr(error.config.url.lastIndexOf('/') + 1);
      }
      if (error.response) {
        const errorResponseStatus = error.response.status;
        switch (errorResponseStatus) {
          case 401: {
            originalRequest._retry = true;
            if (urlLastIndex !== 'login' && urlLastIndex !== 'logout') store.dispatch('auth/logout'); // do not dispatch logout if error from calling login and logout
            if (error.response.config.data && !Object.prototype.hasOwnProperty.call(error.response.config.data, 'password')) {
              Vue.prototype.$dialog.notify.error('terjadi kesalahan. Silahkan masuk kembali', { timeout: 5000 });
            }
            return Promise.reject(error);
          }
          case 403: {
            Vue.prototype.$dialog.notify.error(error.response?.data?.message || Strings.messages.errors.NO_ACCESS, { timeout: 5000 });
            if (urlLastIndex !== 'login' && urlLastIndex !== 'logout') {
              setTimeout(() => {
                store.dispatch('auth/logout', 403);
              }, 100);
            }
            return Promise.reject(error);
          }
          case 404: {
            Vue.prototype.$dialog.notify.error(error.response?.data?.message || Strings.messages.errors.NOT_FOUND, { timeout: 5000 });
            return Promise.reject(error);
          }
          case 500: {
            Vue.prototype.$dialog.notify.error(Strings.messages.errors.INTERNAL_SERVER_ERROR, { timeout: 5000 });
            return Promise.reject(error);
          }
          default:
            if (error.response.data.validation) {
              const validation = Object.keys(error.response.data.validation);
              Vue.prototype.$dialog.notify.error(error.response.data.validation[validation[0]], { timeout: 5000 });
              return Promise.reject(error);
            }
            Vue.prototype.$dialog.notify.error(error.response.data.message, { timeout: 5000 });
            return Promise.reject(error);
        }
      } else if (error.request) {
        Vue.prototype.$dialog.notify.error(Strings.messages.errors.NO_INTERNET, { timeout: 5000 });
      } else {
        if (error.message === 'CANCELED_BY_THE_USER') return;
        Vue.prototype.$dialog.notify.error(Strings.messages.errors.COMMON, { timeout: 5000 });
      }
      // Do something with response error
      return Promise.reject(error);
    });
}
