export default {
  SEARCHNO: 'Cari Nomor Invoice',
  TITLE_PAGE: 'Invoice',
  ADD_TRANSPORT: 'Kendaraan',
  TRANSPORTER_NAME: 'Nama Rekanan 3PL',
  INVOICE_NAME: 'Invoice',
  INVOICE_NO: 'Nomor Invoice',
  ORDER_PERIODE: 'Periode Pesanan',
  INVOICE_TOTAL: 'Total Tagihan',
  CREATED_BY: 'Dibuat Oleh',
  INVOICE_TYPE: 'Tipe',
  STATUS: 'Status',
  ACTION: 'Aksi',
  SUCCESS_MESSAGE_TEXT: 'Data berhasil dibuat',
  EDIT_MESSAGE_TEXT: 'Data berhasil diubah',
  FILTER_STATUS: 'Semua Status',
  FILTER_TYPE: 'Semua Tipe',
  FILTER_3PL: 'Semua Rekanan 3PL',
  KOSONG: '.',
  REKANAN_3PL: 'Rekanan 3PL',
  DATE: 'Tanggal',
  TITLE_PAGE_SHIPPER: 'Invoice Shipper',
  SHIPPER_NAME: 'Nama Shipper',
  FILTER_SHIPPER: 'Semua Shipper',
  SHIPPER: 'Shipper',
  ADD_TEXT: 'Tambah',
  TITLE_CREATE: 'Buat Invoice',
  ORDER_NO: 'No Pesanan',
  ORDER_NO_SHIPPER: 'No Pesanan - Nama Shipper',
  PRICE: 'Harga',
  INVOICE_DOCUMENT: 'Kelengkapan Dokumen',
  SELECT: 'Pilih',
  CUSTOMER_NAME: 'Nama Customer',
  SUCCESS_INVOICE_MESSAGE_TEXT: 'Invoice berhasil dibuat',
  TITLE_EDIT: 'Perbarui Invoice',
  QUANTITY: 'Quantity',
  PRICE_UNIT: 'Harga Per Unit',
  FILTER: 'Filter Berdasarkan',
  CHOOSE_STATUS: 'Pilih Status',
  CHOOSE_TYPE: 'Pilih Tipe',
  COMPANY_NAME: 'Nama Perusahaan',
  PAYMENT_PERIOD: 'Periode Pembayaran',
  PERIOD: 'Periode',
  ORDER_LIST: 'Daftar Pesanan',
  EDIT_STATUS_MESSAGE_TEXT: 'Status invoice berhasil diubah',
  DRIVER: 'Sopir',
  TRANSPORT_TYPE: 'Tipe Kendaraan',
  FILTER_STATUS_LIST: [
    {
      text: 'Proforma',
      value: 'PROFORMA',
    },
    {
      text: 'Belum dicetak',
      value: 'ISSUED',
    },
    {
      text: 'Sudah dicetak',
      value: 'PRINTED',
    },
    {
      text: 'Sudah dikirim',
      value: 'SENT',
    },
    {
      text: 'Sudah diterima',
      value: 'RECEIVED',
    },
    {
      text: 'Sudah dibayar',
      value: 'PAID',
    },
  ],
  FILTER_TYPE_LIST: [
    {
      text: 'Pengiriman',
      value: 'SHIPMENT',
    },
    {
      text: 'Biaya',
      value: 'COST',
    },
    {
      text: 'Biaya dikenakan pajak',
      value: 'COST_TAXED',
    },
  ],
  INVOICE_LIST: 'Daftar Invoice',
};
