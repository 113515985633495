<template>
  <v-container fluid>
    <v-row class="my-n6">
      <v-col cols="auto" :md="4" class="mt-1">
        <span class="body-2">
          {{inputLabel}}<span  v-if="$attrs.rules" class="text-h5 error--text d-inline">*</span>
        </span>
      </v-col>
      <!-- <v-col cols="auto" class="mt-2">
        :
      </v-col> -->
      <v-col :cols="12" md>
        <v-autocomplete
          v-model="displayValue"
          :label="inputLabel"
          required
          solo
          :disabled="disabled"
          :clearable="!disabled"
          outlined
          dense
          flat
          :search-input.sync="searchInput"
          v-bind="$attrs"
          @change="e => $emit('change', e)"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CommonAutocompleteInput',
  props: {
    inputLabel: String,
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    displayValue: {
      get() {
        return this.value;
      },
      set(modifiedValue) {
        this.$emit('input', modifiedValue);
      },
    },
  },
  watch: {
    searchInput(modifiedValue) {
      this.$emit('searchInputChanged', modifiedValue);
    },
  },
};
</script>

<style>

</style>
