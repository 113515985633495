import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const toDegreesMinutesAndSeconds = (coordinate) => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return `${degrees}°${minutes}'${seconds}"`;
};

/**
 * Convert longitude/latitude decimal degrees to degrees minutes and second
 * @param lat latitude degrees decimal
 * @param lng longitude degrees decimal
 */

export const parseLocationDecimal = (lat, lng) => {
  if (!lat && !lng) return '';
  const latitude = toDegreesMinutesAndSeconds(lat);
  const latitudeCardinal = Math.sign(lat) >= 0 ? 'N' : 'S';

  const longitude = toDegreesMinutesAndSeconds(lng);
  const longitudeCardinal = Math.sign(lng) >= 0 ? 'E' : 'W';

  return `${latitude + latitudeCardinal} ${longitude}${longitudeCardinal}`;
};

/**
 * Convert utc timestamp to relative time string
 * @param previous previous time UTC timestamp
 */
export const perseRelativeTime = (previous) => {
  if (!previous) return null;
  const current = new Date();
  previous = new Date(previous);
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + (Math.round(elapsed / 1000) > 1 ? ' detik yang lalu' : ' detik yang lalu');
  } if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + (Math.round(elapsed / msPerMinute) > 1 ? ' menit yang lalu' : ' menit yang lalu');
  } if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + (Math.round(elapsed / msPerHour) > 1 ? ' jam yang lalu' : ' jam yang lalu');
  } if (elapsed < msPerMonth) {
    return `sekitar ${Math.round(elapsed / msPerDay)}${Math.round(elapsed / msPerDay) > 1 ? ' hari yang lalu' : ' hari yang lalu'}`;
  } if (elapsed < msPerYear) {
    return `sekitar ${Math.round(elapsed / msPerMonth)}${Math.round(elapsed / msPerMonth) > 1 ? ' bulan yang lalu' : ' bulan yang lalu'}`;
  }
  return `sekitar ${Math.round(elapsed / msPerYear)}${Math.round(elapsed / msPerYear) > 1 ? ' tahun yang lalu' : ' tahun yang lalu'}`;
};
/**
 * Returns boolean whether previous time is more than inputted minutes
 * @param previous time to be compared
 * @param minutes how many minutes from now
 */
export const isMoreThan = (previous, minutes) => {
  // previous = previous * 1000
  const minutesTimeStamp = minutes * 60 * 1000;
  return previous < ((new Date()) - new Date(minutesTimeStamp));
};

/**
 * parse unix timestamp to date DD-MMMM-YYYY
 * @param {Number} t Unix TimeStamp
 */
export const getDate = (t) => (t ? dayjs.unix(t / 1000).format('DD MMMM YYYY') : '');

/**
 * parse unix timestamp to date DD-MMMM-YYYY HH:MM
 * @param {Number} t Unix TimeStamp
 */
export const getDateTime = (t) => (t ? dayjs.unix(t / 1000).format('DD MMMM YYYY HH:mm') : '');

/**
 * parse unix timestamp to date shorter format DD-MM-YYYY
 * @param {Number} t Unix TimeStamp
 */
export const getDateShort = (t) => (t ? dayjs.unix(t / 1000).format('DD-MM-YYYY') : '');

/**
 * returns Date() from shorter date format DD-MM-YYYY
 * @param {Number} t Unix TimeStamp
 */
export const parseDateShort = (t) => dayjs(t, 'DD-MM-YYYY').toDate();

/**
 * parse unix timestamp to time HH.MM
 * @param {Number} t Unix TimeStamp
 */
export const getTime = (t) => (t ? dayjs.unix(t / 1000).format('HH:mm') : '');

/**
 * parse unix timestamp to date dd MMM YYYY
 * @param {Number} t Unix TimeStamp
 */
export const dateFormat = (t) => {
  if (!t) return '-';
  const monthNames = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'Mei', 'Jun',
    'Jul', 'Agu', 'Sep',
    'Okt', 'Nov', 'Des',
  ];
  const date = new Date(t);
  const getDay = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const day = getDay < 10 ? `0${getDay}` : getDay;
  return `${day} ${monthNames[monthIndex]} ${year}`;
};

/**
 * parse date to HH:mm
 * @param {Number} t date
 */
export const timeFormat = (date) => dayjs(date).format('HH:mm');

export const getDate3 = (t) => {
  if (!t) return '-';
  const monthNames = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'Mei', 'Jun',
    'Jul', 'Agustus', 'Sep',
    'Okt', 'Nov', 'Des',
  ];
  const date = new Date(t);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${day}-${monthNames[monthIndex]}-${year}`;
};

export const getDate4 = (t) => {
  if (!t) return '-';
  const monthNames = [
    'Januari', 'Febuari', 'Maret',
    'April', 'Mei', 'Juni',
    'Juli', 'Agustus', 'September',
    'Oktober', 'November', 'Desember',
  ];
  const date = new Date(t);
  const getDay = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const day = getDay < 10 ? `0${getDay}` : getDay;
  return `${day} ${monthNames[monthIndex]} ${year}`;
};

/**
 * Returns thousand separated number as string
 * @param {String|Number} i number to be formatted
 */
export const thousandSeparated = (i) => {
  if (!i) return 0;
  const string = i.toString();
  const number = Number(string.replace(/[^0-9.-]+/g, '')); // cleanse input to only process number
  const rounded = Math.round(number);
  const formatter = new Intl.NumberFormat('id');
  return formatter.format(rounded);
};
export const getTimestampDaysBefore = (t = Date.now(), day = 0) => {
  const daysBeforeTimestamp = t - (day * 24 * 60 * 60 * 1000);
  return daysBeforeTimestamp;
};

/**
 * parse unix timestamp to ISO date YYYY-MM-DD
 * @param {Number} t unix timestamp
 * @returns iso date string
 */
export const getISODate = (t) => (t ? dayjs.unix(t / 1000).format('YYYY-MM-DD') : '');

/**
 * parse ISO date YYYY-MM-DD to unix timestamp
 * @param {String} s ISO date YYYY-MM-DD
 * @returns unix timestamp
 */
export const getUnixTime = (s) => (s ? dayjs(s, 'YYYY-MM-DD').valueOf() : '');

export const statusActionWorkflow = (status) => {
  switch (status) {
    case 'SUBMIT':
      return 'Menunggu Persetujuan';
    case 'APPROVE':
      return 'Disetujui';
    case 'REJECT':
      return 'Ditolak';
    case 'REVISE':
      return 'Revisi';
    case 'DRAFT':
      return 'Draft';
    case 'NONACTIVE':
      return 'Tidak Aktif';
    case 'ACTIVE':
      return 'Aktif';
    default:
      return status;
  }
};

export const transportModel = (type) => {
  switch (type) {
    case 'AIR': return 'Udara';
    case 'LAND': return 'Darat';
    case 'SEA': return 'Laut';
    default: return '-';
  }
};

export const statusOrder = (type) => {
  switch (type) {
    // WAITING
    case 'PESANAN_DIBUAT': return 'Pesanan Dibuat';
    case 'PENJEMPUTAN': return 'Penjemputan';
    case 'MENUNGGU_KONFIRMASI_TRANSPORTER': return 'Menunggu Konfirmasi Transporter';
    case 'MENUNGGU_KONFIRMASI': return 'Menunggu Konfirmasi';
    case 'MENUNGGU_KONFIRMASI_DRIVER': return 'Menunggu Konfirmasi Driver';
    case 'DRIVER_WAKTU_HABIS': return 'Driver Waktu Habis';
    // ON_PROCESS
    case 'DILOKASI_PENJEMPUTAN': return 'Dilokasi Penjemputan';
    case 'PENGIRIMAN': return 'Pengiriman';
    case 'DITERIMA_UTUH': return 'Diterima Utuh';
    case 'TERKONFIRMASI': return 'Terkonfirmasi';
    case 'DOKUMEN_POD_DIKIRIM': return 'Dokumen POD Dikirim';
    case 'DOKUMEN_POD_LENGKAP': return 'Dokumen POD Lengkap';
    case 'TERIMA_ASSIGNMENT': return 'Terima Assignment';
    case 'BA_PENERIMAAN_KONFIRMASI_SHIPPER': return 'BAP - Konfirmasi Shipper';
    case 'BARANG_DITERIMA_UTUH': return 'Barang Diterima Utuh';
    case 'BARANG_DITERIMA_TIDAK_UTUH': return 'Barang Diterima Tidak Utuh';
    case 'BAP_PENERIMAAN_KONFIRMASI_SHIPPER': return 'BAP - Konfirmasi Shipper';
    case 'BA_PENERIMAAN_GANTI_LOKASI_TUJUAN': return 'BA Penerimaan - Ganti Lokasi Tujuan';
    case 'BELUM_KIRIM_DOKUMEN': return 'Belum Kirim Dokumen';
    case 'DI_LOKASI_PENJEMPUTAN': return 'Di Lokasi Penjemputan';
    case 'WAKTU_HABIS': return 'Waktu Habis';
    case 'DIBATALKAN': return 'Dibatalkan';
    case 'DOKUMEN_TERKIRIM': return 'Dokumen Terkirim';
    case 'DI_LOKASI_TUJUAN': return 'Di Lokasi Tujuan';
    case 'BA_PENERIMAAN_KONFIRMASI': return 'Ba Penerimaan Konfirmasi';
    case 'DITOLAK': return 'Ditolak';
    // FINISH
    case 'BA_PENERIMAAN_KEMBALI_KE_GUDANG_ASAL': return 'BA Penerimaan - Kembali Ke Gudang asal';
    case 'BA_PENERIMAAN_DITERIMA_GUDANG': return 'BA Penerimaan - Diterima Gudang';
    case 'KEDALUWARSA': return 'Kedaluwarsa';
    // MULTIMODA
    case 'MENUNGGU_MUAT_BARANG': return 'Menunggu Muat Barang';
    case 'MUAT_BARANG': return 'Muat Barang';
    case 'MENYEBRANG_PENGIRIMAN': return 'Menyebrang Pengiriman';
    case 'DALAM_PENERBANGAN': return 'Dalam Penerbangan';
    case 'DALAM_PENYEBRANGAN': return 'Dalam Penyebrangan';
    case 'SAMPAI_BANDARA': return 'Sampai Bandara';
    case 'SAMPAI_PELABUHAN': return 'Sampai Pelabuhan';
    case 'BONGKAR_MUATAN': return 'Bongkar Muatan';
    case 'DOKUMEN_LENGKAP': return 'Dokumen Lengkap';
    case 'DOKUMEN_TIDAK_LENGKAP': return 'Dokumen Tidak Lengkap';
    default: return type;
  }
};

export const typePriceInvoice = (type) => {
  switch (type) {
    case 'FIXED_PRICE': return 'Fixed Price';
    case 'ONCALL': return 'On Call';
    case 'TONNAGE': return 'Tonase';
    case 'CARTON': return 'Karton';
    case 'CUBIC': return 'Kubik';
    default: return '-';
  }
};

export const shipmentColorStatus = (type) => {
  switch (type) {
    case 'Pesanan Dibuat':
    case 'Menunggu Konfirmasi':
      return 'orange';
    case 'Terkonfirmasi':
    case 'Penjemputan':
    case 'Di Lokasi Penjemputan':
    case 'Pengiriman':
    case 'Di Lokasi Tujuan':
    case 'Dalam Proses':
    case 'BAP - Konfirmasi Shipper':
      return 'blue lighten-1';
    case 'Diterima Tidak Utuh':
    case 'BAP - Diterima Gudang':
    case 'BA Penerimaan - Kembali Ke Gudang asal':
    case 'BAP - Ganti Lokasi Tujuan':
      return '#66BB6A';
    case 'Ditolak':
    case 'Dibatalkan':
    case 'Kedaluwarsa':
      return 'red';
    default: return 'grey';
  }
};

export const p2pLendingStatus = (status) => {
  if (!status) return '-';
  switch (status) {
    case 'DRAFT': return 'Draft';
    case 'REJECTED': return 'Ditolak';
    case 'APPROVED': return 'Disetujui';
    case 'COMPLETED': return 'Selesai';
    case 'NEED_APPROVAL': return 'Menunggu persetujuan';
    default: return status;
  }
};

export const parseDateStrip = (t) => {
  if (t) {
    return dayjs(t, 'DD/MM/YY HH:mm').format('DD-MM-YYYY');
  }
};

export const parseDateStrip2 = (t) => {
  if (t) {
    return dayjs(t, 'DD/MM/YY').format('DD-MM-YYYY');
  }
};

export const getDateStrip = (t) => {
  if (t) {
    return dayjs(t).format('DD-MM-YYYY');
  }
};

export const parseDateTimeStrip = (t) => {
  if (t) {
    return dayjs(t, 'DD/MM/YY HH:mm').format('DD MMM YYYY HH:mm');
  }
};

export const statusReport = (status) => {
  switch (status) {
    case 'inactive':
      return 'Tidak Aktif';
    case 'active':
      return 'Aktif';
    default:
      return status;
  }
};

export const rulesNotes = (v, isRequired, length, label) => {
  if (!v && isRequired) return `${label} wajib diisi`;
  if (v && v.length > length) return `${label} maksimal ${length} karakter`;
  // FOR SPECIAL CHARACTER
  // const patternNotes = /[^A-Za-z0-9,.\n\s]/;
  // if (patternNotes.test(v)) return `${label} tidak boleh menggunakan spesial karakter`;
  return true;
};

export const statusReportProblem = (status) => {
  switch (status) {
    case 'NEW':
      return 'Baru';
    case 'PROCESS':
      return 'Dalam Proses';
    case 'RESOLVED':
      return 'Selesai';
    default:
      return status;
  }
};

export const querystring = (filters) => {
  const strings = '';
  let result = '';
  Object.keys(filters).forEach((filter) => {
    const text = `${filter}=${filters[filter]}`;
    result = strings.concat(`${result}${result ? '&' : ''}`, text);
  });
  return result;
};

export const renamePeriode = (period) => {
  switch (period) {
    case 'FIX_WEEKLY': return 'Mingguan';
    case 'FIX_MONTHLY': return 'Bulanan';
    default: return '-';
  }
};

export const convertTextTypeCost = (unitPrice) => {
  switch (unitPrice) {
    case 'FIXED_PRICE': return 'Fixed Price';
    case 'CARTON': return 'Karton';
    case 'TONNAGE': return 'Tonase';
    case 'CUBIC': return 'Kubikasi';
    case 'MASSA': return 'Massa';
    case 'KILO': return 'Kilo';
    case 'BOX': return 'Box';
    case 'ONCALL': return 'On Call';
    default: return unitPrice;
  }
};

export const replaceNoteToHtml = (notes = '') => {
  if (!notes) return '-';
  let stringNotes = JSON.stringify(notes);
  stringNotes = stringNotes.replace(/^"|"$/g, '');
  stringNotes = stringNotes.replace(/\\n/g, '<br>');
  return stringNotes;
};

export const skipEmptyStringObject = (newObject) => {
  const skipEmptyString = {};
  Object.keys(newObject).forEach((filter) => {
    if (newObject[filter]
      || typeof newObject[filter] === 'number'
      || typeof newObject[filter] === 'boolean'
    ) {
      skipEmptyString[filter] = newObject[filter];
    }
  });
  return skipEmptyString;
};

export const handleSortBy = ({ defaultValue, sortBy, sortDesc }) => {
  let sort = defaultValue || '';
  if (sortBy.length > 0) {
    const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
    sort = `${sortBy[0]},${isDesc}`;
  }
  return sort;
};

export const getDateTimeTz = () => {
  const date = new Date();
  return date.toString().split('GMT')[1].replace(/0|\D/g, '');
};

export const handlerPagination = (_this, newVal) => {
  const oldQuery = JSON.stringify(_this.$route.query).replace(/"/g, '');
  const newQuery = JSON.stringify(
    skipEmptyStringObject({
      ..._this.$route.query,
      page: newVal.page,
      itemsPerPage: newVal.itemsPerPage,
      sort: handleSortBy({ sortBy: newVal.sortBy, sortDesc: newVal.sortDesc }),
    }),
  ).replace(/"/g, '');
  if (oldQuery !== newQuery) {
    _this.$router.replace({
      query: skipEmptyStringObject({
        ..._this.$route.query,
        page: newVal.page,
        itemsPerPage: newVal.itemsPerPage,
        sort: handleSortBy({ sortBy: newVal.sortBy, sortDesc: newVal.sortDesc }),
      }),
    });
  }
};

export const defaultPagination = () => {
  const search = window.location.search.split('?')[1];
  let parameters = {};
  if (search) {
    const pattern = /\w+=[a-zA-Z0-9,]*/g;
    parameters = JSON.parse(`{"${decodeURI(search)
      .match(pattern)
      .join('&')
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`);
  }
  return {
    itemsPerPage: +parameters.itemsPerPage || 10,
    page: +parameters.page || 1,
    sortBy: parameters.sort ? [parameters.sort.split(',')[0]] : [],
    sortDesc: parameters.sort
      ? [parameters.sort.split(',')[1] === 'DESC']
      : [],
  };
};
