/* eslint-disable import/no-cycle */
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging/sw';
import {
  getToken, isSupported,
} from 'firebase/messaging';
import keys from '../../keys';

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyBBM87S5ieoDLI8oC3duAGQHG0YsmuMhiU',
  authDomain: 'fastlog-development.firebaseapp.com',
  projectId: 'fastlog-development',
  storageBucket: 'fastlog-development.appspot.com',
  messagingSenderId: '408348638345',
  appId: '1:408348638345:web:7a7e42538aef43f1b39a58',
  measurementId: 'G-NL19K1E0BR',
});

const firebaseMessaging = getMessaging(firebaseApp);

const getTokenFcm = async () => {
  try {
    const currentToken = await getToken(firebaseMessaging, { vapidKey: keys().fcmPublicVapidKey });
    if (currentToken) {
      console.log('FCM TOKEN:', currentToken);
      // Send the token to your server and update the UI if necessary
      // ...
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
      return false;
    }
    return currentToken;
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

export async function requestPermission() {
  console.log('Requesting permission...');
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      return true;
    }
    console.log('Unable to get permission to notify.');
    return false;
  } catch (err) {
    console.log('err permission.', err);
  }
}

const setupNotification = async () => {
  try {
    const _isSupported = await isSupported();
    if (_isSupported) {
      console.log('TCL: Notification.permission', Notification.permission);
      let isPermission;
      if (Notification.permission === 'granted') {
        /* do our magic */
        isPermission = true;
      } else if (Notification.permission === 'denied') {
        await requestPermission();

        // throw Error('Notification is blocked');
        /* the user has previously denied push. Can't reprompt. */
      } else {
        /* show a prompt to the user */
        console.log('TCL: show a prompt to the user');
        await requestPermission();
      }
      return isPermission;
    }
    console.log('fcm not supported');
    return false;
  } catch (error) {
    console.log('TCL: setUpNotification -> error', error);
    return false;
  }
};

export {
  firebaseMessaging,
  setupNotification,
  getTokenFcm,
};
