export default {
  DRIVER: 'Sopir',
  SEARCH_DRIVER: 'Cari Nama Sopir',
  PHOTO: 'Foto',
  CREATE_DRIVER: 'Tambah Sopir',
  DETAIL_DRIVER: 'Detail Sopir',
  EDIT_DRIVER: 'Ubah Sopir',
  DRIVER_NAME: 'Nama Sopir',
  EMAIL: 'Email',
  PHONE_NUMBER: 'Nomor Telepon',
  LICENSE_NO: 'Nomor Sim',
  LICENSE_EXP_DATE: 'Masa Berlaku Sim',
  CREATED_BY: 'Dibuat Oleh',
  STATUS: 'Status',
  DELETE_DRIVER_CONFIRM_MSG: 'Apakah anda yakin ingin menghapus akun sopir ini?',
  RESET_DRIVER_CONFIRM_MSG: 'Apakah anda yakin ingin mereset password akun sopir ini?',
  SUCCESSFUL_DELETE_DRIVER: 'Berhasil menghapus akun',
  EDITED_DRIVER_CONFIRM_MSG: 'Berhasil mengubah akun driver',
  SUCCESSFUL_ADD_DRIVER: 'Berhasil menambahkan akun driver',
  RESET_PASSWORD: 'Reset Password',
  RESET_PASSWORD_CONFIRM_MSG: 'Apakah Anda yakin ingin me-reset password akun ini?',
  RESET_PASSWORD_SUCCESS_MSG: 'Berhasil me-reset password akun',
  ALL_STATUS: 'Semua Status',
  PAGE_NAME: 'Daftar Sopir',
  DRIVER_PHOTO: 'Foto Sopir',
  SIM_PHOTO: 'Foto Sim',
  SIM_VALIDITY_PERIOD: 'Masa Berlaku Sim',
  DELETE_USER_CONFIRM_MESSAGE: 'Apakah anda yakin ingin menghapus user ini?',
  ACTIVE: 'Aktif',
  IN_ACTIVE: 'Tidak Aktif',
  LIST_DRIVER: 'Daftar Driver',
  DRIVER_STATUS_EDITED: 'Status driver berhasil diubah',
  SUSPEND: 'Suspend',
  ACTIVE_SUSPEND: 'Ya',
  IN_ACTIVE_SUSPEND: 'Tidak',
  EDIT_STATUS_SUSPEND_MSG: 'Suspend driver berhasil diubah',
  VACCINE_INFO: 'Informasi Vaksin',
  HAVE_VACCINE: 'Apakah Anda Sudah Vaksin?',
  VACCINE: 'Vaksin',
  VACCINE_NAME: 'Nama Vaksin',
  VACCINE_DATE: 'Tanggal Vaksin',
  ADD_VACCINE: 'Tambah Vaksin',
  CREATE_DRIVER_PAGE_NAME: 'Buat Driver',
  EDIT_DRIVER_PAGE_NAME: 'Ubah Driver',
};
