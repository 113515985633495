/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';
/**
 * fetch selection list
 * @param {Object} filters selection list filter
 *  @param {Number} filters.page list pagination page number
 *  @param {Number} filters.size list pagination entry count
 *  @param {String} filters.value list pagination entry count
 */
const fetchSelectionsInfos = async ({
  page, size, value,
}, importType) => {
  try {
    const filter = {
      page, size,
    };
    const types = importType.map((e) => e.type);
    types.toString();
    const res = await axios.get(`${urls.selectionInfo}/filter?${querystring.stringify(filter)}&type=qIn(${types.toString()})&name=qLike(${value})`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListMasterShipper = async (filters) => {
  try {
    const res = await axios.get(`${urls.masterShipperList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchSelectionsInfos,
  getListMasterShipper,
};
