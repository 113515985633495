import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getShipmentSummary = async () => {
  try {
    const res = await axios.get(urls.shipmentSummary);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDashboardList = async ({
  page,
  cargoTitle,
  companyTransportNo,
  size,
  sort,
  fromDate,
  toDate,
  search,
  latestStatus,
  originLocationId,
  destinationLocationId,
}, source) => {
  try {
    const filters = {
      page, size, fromDate, toDate, search, latestStatus, originLocationId, destinationLocationId, companyTransportNo,
    };
    if (cargoTitle) {
      filters.cargoTitle = `${cargoTitle}`;
    } else {
      delete filters.cargoTitle;
    }
    if (latestStatus) {
      filters.latestStatus = latestStatus;
    } else {
      delete filters.latestStatus;
    }
    if (originLocationId) {
      filters.originLocationId = originLocationId;
    } else {
      delete filters.originLocationId;
    }
    if (destinationLocationId) {
      filters.destinationLocationId = destinationLocationId;
    } else {
      delete filters.destinationLocationId;
    }
    if (sort) {
      filters.sort = sort;
    } else {
      filters.sort = 'createdAt,desc';
    }
    const res = await axios.get(`${urls.dashboardList}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export {
  getShipmentSummary,
  getDashboardList,
};
