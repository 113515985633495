/* eslint-disable import/no-cycle */
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const listReports = async () => {
  try {
    const res = await axios.get(`${urls.reports}/type/3PL`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const exportReportToCSV = async (id, filters) => {
  try {
    const res = await axios.get(`${urls.reports}/export/CSV/${id}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const previewReport = async (id, filters) => {
  try {
    const res = await axios.get(`${urls.reports}/show/CSV/${id}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  listReports,
  exportReportToCSV,
  previewReport,
};
