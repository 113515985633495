export default {
  label: {
    PAGE_NAME: 'Jadwal',
    PARTNER_3PL: 'Rekanan 3PL',
    CUSTOMER: 'Customer',
    CUSTOMER_NAME: 'Nama Customer',
    PARTNER_3PL_NAME: 'Nama Rekanan 3PL',
    APPLY_FILTER: 'Terapkan Filter',
    SCHEDULED_ORDER: 'Pesanan Terjadwal',
    LIST_SCHEDULED_ORDER: 'List Pesanan terjadwal',
    ON_PROCESS: 'Dalam Proses',
    LIST_ON_PROCESS: 'List dalam proses',
    ORDER_COMPLETED: 'Pesanan Selesai',
    LIST_ORDER_COMPLETED: 'List pesanan selesai',
    ORDER_CANCEL: 'Pesanan Batal',
    LIST_ORDER_CANCEL: 'List pesanan batal',
    NO_DATA: 'Tidak ada data',
    SCHEDULED: 'Terjadwal',
  },
};
