export default {
  title: 'Biaya Ongkos Kirim',
  postageCostDatabase: 'Master Biaya Ongkos Kirim',
  pageName: 'Daftar Biaya Ongkos Kirim',
  shippingCostTypeName: 'Nama Biaya',
  originLocationName: 'Lokasi Asal',
  destinationLocationName: 'Lokasi Tujuan',
  transportTypeName: 'Tipe Kendaraan',
  serviceTypeName: 'Tipe Service',
  amount: 'Biaya (Rp)',
  createdBy: 'Dibuat Oleh',
  inputNamaBiayaLabel: 'Nama Biaya',
  inputServiceType: 'Service Type',
  inputLokasiAsalLabel: 'Lokasi Asal',
  inputLokasiTujuanLabel: 'Lokasi Tujuan',
  inputTipeKendaraan: 'Tipe Kendaraan',
  inputBiayaLabel: 'Biaya',
  editPostageCostConfirmMsg: 'Berhasil mengubah biaya ongkos kirim',
  successUpdatePostageCostrMsg: 'Berhasil menambah biaya ongkos kirim',
  deletePostageCostConfirmMsg: 'Apakah anda yakin ingin menghapus akun biaya sopir ini?',
  add: 'Tambah Biaya',
};
