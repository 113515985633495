import common from './common';

const uploadCsvButtonLabel = 'Upload Csv';
const downloadCsvTemplateButtonLabel = 'Template Csv';
const uploadCsvLabelDataTypeCustomerDetail = 'Customer detail';
const uploadCsvLabelDataTypeCustomerPriceCustom = 'Customer custom price';
const uploadCsvLabelDataTypeCustomerPriceFix = 'Customer fix price';
const uploadCsvLabelDataTypeTransporterDetail = 'Rekanan 3PL detail';
const uploadCsvLabelDataTypeTransporterPriceCustom = 'Rekanan 3PL custom price';
const uploadCsvLabelDataTypeTransporterPriceFix = 'Rekanan 3PL fix price';
const uploadCsvLabelDataTypeCategory = 'Kategori';
const uploadCsvLabelDataTypeTransport = 'Kendaraan';
const uploadCsvLabelDataDriver = 'Driver';
const uploadCsvLabelDataTypeTransportType = 'Tipe Kendaraan';
const uploadCsvLabelDataTypeCostOption = 'Pilihan biaya Tambahan';
// const uploadCsvLabelDataTypeOriginOption = 'Keterangan lokasi asal'
// const uploadCsvLabelDataTypeDestinationOption = 'Keterangan lokasi tujuan'
const uploadCsvLabelDataTypeCancelOption = 'Alasan pembatalan';
const uploadCsvLabelDataTypeAccidentOption = 'Keterangan kecelakaan';
const uploadCsvLabelDataTypeLocation = 'Lokasi';
const uploadedCsvDataStatusListButton = 'Status data yang diupload';
const uploadedCsvDataStatusListDialogTitle = 'Status data yang telah diunggah';
const uploadedCsvDataDetailListDialogTitle = 'Detail data yang telah diunggah';
const uploadCsvSuccessMessage = 'Berhasil meng-unggah file';
const csvListTableUploaderColumnLabel = 'Diunggah oleh';
const csvListTableUploadedAtColumnLabel = 'Diunggah pada';
const csvListTableStatusColumnLabel = 'Status proses';
const csvListTableSuccessRecordCountColumnLabel = 'Sukses';
const csvListTableFailedRecordCountColumnLabel = 'Gagal';
const csvListTablePagination = (page, length, size, totalEntry) => common.commonTablePagination(page, length, size, totalEntry);
const csvDataTypeOptions = [
  {
    label: uploadCsvLabelDataTypeCustomerDetail,
    dataType: 'customer_detail',
    databaseType: 'customer',
  },
  {
    label: uploadCsvLabelDataTypeCustomerPriceCustom,
    dataType: 'customer_price_custom',
    databaseType: 'customer',
  },
  {
    label: uploadCsvLabelDataTypeCustomerPriceFix,
    dataType: 'customer_price_fix',
    databaseType: 'customer',
  },
  {
    label: uploadCsvLabelDataTypeTransporterDetail,
    dataType: 'transporter_detail',
    databaseType: 'transporter',
  },
  {
    label: uploadCsvLabelDataTypeTransporterPriceCustom,
    dataType: 'transporter_price_custom',
    databaseType: 'transporter',
  },
  {
    label: uploadCsvLabelDataTypeTransporterPriceFix,
    dataType: 'transporter_price_fix',
    databaseType: 'transporter',
  },
  {
    label: uploadCsvLabelDataTypeCategory,
    dataType: 'category',
    databaseType: 'category',
  },
  {
    label: uploadCsvLabelDataDriver,
    dataType: 'driver',
    databaseType: 'driver',
  },
  {
    label: uploadCsvLabelDataTypeTransportType,
    dataType: 'transport_type',
    databaseType: 'transport_type',
  },
  {
    label: uploadCsvLabelDataTypeTransport,
    dataType: 'transport',
    databaseType: 'transport',
  },
  {
    label: uploadCsvLabelDataTypeCostOption,
    dataType: 'cost',
    databaseType: 'selection_info',
  },
  // {
  //   label: uploadCsvLabelDataTypeOriginOption,
  //   dataType: 'origin_option',
  //   databaseType: 'selection_info'
  // },
  // {
  //   label: uploadCsvLabelDataTypeDestinationOption,
  //   dataType: 'destination_option',
  //   databaseType: 'selection_info'
  // },
  {
    label: uploadCsvLabelDataTypeCancelOption,
    dataType: 'cancellation',
    databaseType: 'selection_info',
  },
  {
    label: uploadCsvLabelDataTypeAccidentOption,
    dataType: 'accident',
    databaseType: 'selection_info',
  },
  {
    label: uploadCsvLabelDataTypeLocation,
    dataType: 'location',
    databaseType: 'location',
  },
];

export default {
  uploadCsvButtonLabel,
  downloadCsvTemplateButtonLabel,
  uploadCsvLabelDataTypeCustomerDetail,
  uploadCsvLabelDataTypeCustomerPriceCustom,
  uploadCsvLabelDataTypeCustomerPriceFix,
  uploadCsvLabelDataTypeTransporterDetail,
  uploadCsvLabelDataTypeTransporterPriceCustom,
  uploadCsvLabelDataTypeTransporterPriceFix,
  uploadCsvLabelDataTypeCategory,
  uploadCsvLabelDataTypeTransportType,
  uploadCsvLabelDataTypeTransport,
  uploadCsvLabelDataDriver,
  uploadCsvLabelDataTypeCostOption,
  // uploadCsvLabelDataTypeOriginOption,
  // uploadCsvLabelDataTypeDestinationOption,
  uploadCsvLabelDataTypeCancelOption,
  uploadCsvLabelDataTypeAccidentOption,
  uploadCsvLabelDataTypeLocation,
  uploadedCsvDataStatusListButton,
  uploadedCsvDataStatusListDialogTitle,
  uploadedCsvDataDetailListDialogTitle,
  uploadCsvSuccessMessage,
  csvListTableUploaderColumnLabel,
  csvListTableUploadedAtColumnLabel,
  csvListTableStatusColumnLabel,
  csvListTablePagination,
  csvDataTypeOptions,
  csvListTableSuccessRecordCountColumnLabel,
  csvListTableFailedRecordCountColumnLabel,
};
