import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const fetchLocationList = async (filters) => {
  try {
    filters.sort = 'name,asc';
    const { name } = filters;
    if (name) filters.name = `qlike(${name})`;
    const res = await axios.get(`${urls.locationList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get province list
 * @param {String} input province name search
 */
const provinces = async (input) => {
  try {
    const filters = {
      page: 0,
      size: 1000,
      sort: 'name,asc',
    };
    if (input) filters.name = input;
    const res = await axios.get(`${urls.provinceList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get city list (kodya)
 * @param {String} input city name search
 * @param {Number} provinceId province id to get cities from
 */
const cities = async (input, provinceId) => {
  try {
    const filters = {
      page: 0,
      size: 1000,
      sort: 'name,asc',
    };
    if (input) filters.name = input;
    const res = await axios.get(`${urls.cityList(provinceId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get districts list (kecamatan)
 * @param {String} input districts name search
 * @param {Number} cityId city id to get districts from
 */
const districts = async (input, cityId) => {
  try {
    const filters = {
      page: 0,
      size: 1000,
      cityId,
      sort: 'name,asc',
    };
    if (input) filters.name = input;
    const res = await axios.get(`${urls.districtList(cityId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get subDistricts list (kelurahan)
 * @param {String} input subDistricts name search
 * @param {Number} districtId district id to get subDistricts from
 */
const subDistricts = async (input, districtId) => {
  try {
    const filters = {
      page: 0,
      size: 1000,
      districtId,
      sort: 'name,asc',
    };
    if (input) filters.name = input;
    const res = await axios.get(`${urls.subDistrictList(districtId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  provinces,
  cities,
  districts,
  subDistricts,
  fetchLocationList,
};
