<template>
  <viewer
    :options="options"
  >
    <img
      :style="{
        objectFit: 'cover',
        width: '100%',
        cursor: 'pointer'
      }"
      :src="src"
      :height="height"
    >
  </viewer>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      options: {
        title: false,
      },
    };
  },
};
</script>

<style>
  ul>li:nth-child(5), li:nth-child(7) {
    display: none !important;
  }
</style>
