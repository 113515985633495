export default {
  headerMasalah: 'Masalah',
  headerNokiriman: 'No Kiriman',
  headerNoPesanan: 'No Kargo',
  headerRekanan3PL: 'Rekanan 3PL',
  headerKendaraan: 'Kendaraan',
  headerSopir: 'Sopir',
  headerAksi: 'Aksi',
  pageName: 'Daftar Masalah',
  handledByDriver: 'Ditangani Driver',
  handledByAdmin: 'Ditangani Admin FLI/3PL',
  proses: 'Proses',
  selesaikan: 'Selesaikan',
  searchNo: 'Cari No. Kiriman',
  headerDoneProblem: 'Apakah anda yakin ingin memproses masalah ini?',
  headerNote: 'Catatan',
  buttonBatal: 'Batal',
  buttonBatalAssign: 'Batal Pengiriman',
  buttonNextAssign: 'Lanjutkan Pengiriman',
  alertDoneProblem: 'Masalah telah diselesaikan',
  headerAssign: 'Warning',
  cancel: 'Batal',
  yes: 'Ya',
  problemDetail: 'Detail Masalah Pengiriman',
  solution: 'Solusi',
  resolvedBy: 'Oleh',
  resolvedDate: 'Diselesaikan Pada',
  note: 'Catatan',
  foto: 'Foto',
  shippingProblem: 'Masalah Pengiriman',
  location: 'Lokasi',
  address: 'Alamat',
};
