export default {
  PAGE_NAME: 'Pengaturan User Admin',
  CREATE_USERADMIN_PAGE_NAME: 'Buat user admin baru',
  EDIT_USERADMIN_PAGE_NAME: 'Ubah user admin',
  CREATE_USERADMIN_BUTTON_LABEL: 'Tambah user admin',
  SEARCH_USERADMIN_INPUT_PLACEHOLDER: 'Cari nama user admin',
  NAME_TABLE_HEADER_LABEL: 'Nama',
  EMAIL_TABLE_HEADER_LABEL: 'Email',
  ROLE_TABLE_HEADER_LABEL: 'Role',
  STATUS_TABLE_HEADER_LABEL: 'Status',
  RESETPASSWORD_BTN_LABEL: 'Reset Password',
  ACTIVATED_OPTION_LABEL: 'Aktif',
  INACTIVATED_OPTION_LABEL: 'Tidak Aktif',
  PHONE_INPUT_LABEL: 'No Telepon',
  IMAGE_INPUT_LABEL: 'Gambar',
  DELETE_USER_CONFIRM_MESSAGE: 'Apakah anda yakin ingin menghapus user ini?',
  SUCCESS_CREATE_USER_MESSAGE_TEXT: 'Data user berhasil dibuat, silahkan cek email untuk proses aktivasi.',
  SUCCESS_EDIT_USER_MESSAGE_TEXT: 'Data user berhasil diubah',
  STATUS_USER_ADMIN: 'Semua Status',
  DB_NAME: 'User Admin',
  SUCCESS_RESET_USER_MESSAGE_TEXT: 'Password berhasil direset',
  SUCCESS_RESEND_USER_MESSAGE_TEXT: 'Sistem telah mengirimkan kembali email untuk aktivasi',
  SUCCESS_DELETE_USER_MESSAGE_TEXT: 'Data user berhasil dihapus',
  NAME_ACCOUNT_HEADER_LABEL: 'Nama Perusahaan',
  SUCCESS_RESET_PASSWORD_USER_MESSAGE_TEXT: 'Silahkan cek kotak masuk email untuk melanjutkan proses reset password',
};
