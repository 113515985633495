import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';
// eslint-disable-next-line import/no-cycle
import store from '../../store';

/**
 * fetch driver list
 * @param {Object} filter
 *  @param {Number} filter.page Page number
 *  @param {Number} filter.size list size
 *  @param {String} filter.sort sort by (<field>, Asc|Desc)
 *  @param {String} filter.name search text
 */
const postageCostList = async ({
  page, size, sort,
}) => {
  try {
    const filter = {
      page, size,
    };
    if (sort) {
      filter.sort = sort;
    } else {
      filter.sort = 'createdAt,desc';
    }
    const res = await axios.get(`${urls.postageCostList}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create and edit a postage-cost detail
 * @param {Object} form postage-cost detail form
   * @param {String} form.shippingCostTypeId
   * @param {String} form.originLocationId
   * @param {Number} form.destinationLocationId
   * @param {Number} form.transportTypeId
   * @param {Number} form.amount province object
   * @param {Number} [postageCostId] postageCost id for edit/update
 */

const postageCostAddEdit = async (form, postageCostId) => {
  try {
    let res;
    const {
      shippingCostTypeId,
      serviceType,
      originLocationId,
      destinationLocationId,
      transportTypeId,
      amount,
    } = form;
    const data = {
      shippingCostTypeId,
      serviceType,
      originLocationId,
      destinationLocationId,
      transportTypeId,
      amount,
    };
    if (postageCostId) {
      res = await axios.put(`${urls.postageCostDetail(postageCostId)}`, data);
      return res.data;
    }
    res = await axios.post(`${urls.postageCostCreate}`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const transportType = async () => {
  try {
    const res = await axios.get(urls.transportType);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const findPostageCost = async () => {
  try {
    const res = await axios.get(`${urls.findPostageCost}?isActive=qistrue`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCompanyPostageCost = async (shippingId) => {
  try {
    const res = await axios.get(urls.findIdCompany(shippingId));
    const resDetail = await axios.get(urls.findIdCompanyDetail(res.data.data.id));
    return resDetail.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const findRoutePostageCost = async (serviceType, shippingCostId) => {
  try {
    const res = await axios.get(urls.findRoutePostageCost(shippingCostId, serviceType));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deletePostageCost = async (postageCostId) => {
  try {
    const res = await axios.delete(urls.deletePostageCost(postageCostId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchDetailPostageCost = async (id) => {
  try {
    const res = await axios.get(`${urls.detailPostageCost(id)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getServiceType = async () => {
  try {
    const res = await axios.get(urls.serviceType);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTransportType = async (serviceType) => {
  try {
    const res = await axios.get(`${urls.serviceType}/${serviceType}/transport-type`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getLocations = async (serviceType, transportTypeId) => {
  try {
    const res = await axios.get(`${urls.serviceType}/${serviceType}/transport-type/${transportTypeId}/route`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  postageCostList,
  transportType,
  postageCostAddEdit,
  fetchCompanyPostageCost,
  findRoutePostageCost,
  findPostageCost,
  deletePostageCost,
  fetchDetailPostageCost,
  getServiceType,
  getTransportType,
  getLocations,
};
