import querystring from 'query-string';
import axios from 'axios';
import urls from './urls';

const getAllList = async (filters) => {
  try {
    const res = await axios.get(`${urls.orderList}/filter/document-pod?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getList = async (shipmentCargoId) => {
  try {
    const res = await axios.get(`${urls.orderList}/${shipmentCargoId}/document-pod/detail`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getImagePod = async (shipmentCargoId, documentId) => {
  try {
    const res = await axios.get(`${urls.orderList}/${shipmentCargoId}/document-pod/detail/${documentId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadImage = async (shipmentCargoId, data) => {
  try {
    const res = await axios.post(`${urls.orderList}/${shipmentCargoId}/document-pod/upload`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateImage = async (shipmentCargoId, documentId, data) => {
  try {
    const res = await axios.patch(`${urls.orderList}/${shipmentCargoId}/document-pod/upload/${documentId}`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const savePod = async (shipmentCargoId) => {
  try {
    const res = await axios.post(`${urls.orderList}/${shipmentCargoId}/document-pod/submit`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deletePod = async (shipmentCargoId, documentId) => {
  try {
    const res = await axios.delete(`${urls.orderList}/${shipmentCargoId}/document-pod/${documentId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getAllList,
  getList,
  getImagePod,
  uploadImage,
  updateImage,
  savePod,
  deletePod,
};
