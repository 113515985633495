/* eslint-disable import/no-cycle */
import axios from 'axios';
import interceptorSetup from './interceptors';

import * as auth from './auth';
import * as user from './user';
import * as account from './account';
import * as userAdmins from './userAdmins';
import * as locations from './locations';
import * as registration from './registration';
import * as uploads from './uploads';
import * as legalDocument from './legalDocument';
import * as order from './order';
import * as approval from './approval';
import * as driver from './driver';
import * as transport from './transport';
import * as invoiceData from './invoiceData';
import * as postageCost from './postageCost';
import * as email from './email';
import * as mou from './mou';
import * as masterData from './masterData';
import * as invoice from './invoice';
import * as dashboard from './dashboard';
import * as notifications from './notifications';
import * as eta from './eta';
import * as schedule from './schedule';
import * as damageAndMissing from './damageAndMissing';
import * as report from './report';
import * as shippingProblem from './shippingProblem';
import * as podDocument from './podDocument';
import * as wording from './wording';
import * as p2pLending from './p2pLending';
import * as contract from './contract';

if (process.env.VUE_APP_API_TARGET === 'mock') {
  console.log('TCL: services index VUE_APP_API_TARGET', process.env.VUE_APP_API_TARGET);
  // eslint-disable-next-line global-require
  require(/* webpackChunkName: "mock-services" */ '../mock-services'); // import mock adapter if env is mock
}

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
interceptorSetup();

export {
  auth,
  user,
  account,
  userAdmins,
  locations,
  registration,
  uploads,
  legalDocument,
  order,
  approval,
  driver,
  transport,
  invoiceData,
  postageCost,
  email,
  mou,
  masterData,
  invoice,
  dashboard,
  notifications,
  eta,
  schedule,
  damageAndMissing,
  report,
  shippingProblem,
  podDocument,
  wording,
  p2pLending,
  contract,
};
