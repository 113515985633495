export default {
  WAITING: 'Menunggu',
  PROCESS: 'Dalam Proses',
  COMPLETED: 'Selesai',
  GROUP_TITLE_NUMBER: 'No Kiriman',
  ORDER_NO: 'No Kargo',
  DELIVERY_DATE: 'Tgl Kirim',
  CREATE_DATE: 'Tgl Buat',
  ORIGIN: 'Lokasi Asal',
  DESTINATION: 'Lokasi Tujuan Akhir',
  DESC_TARIF: 'Ket Tarif',
  ORDER_NUMBER: 'No Pesanan',
  CUSTOMER_NAME: 'Nama PIC Pengirim',
  MULTIPICK: 'Multipick',
  MULTIDROP: 'Multidrop',
  MULTIPICK_LOCATION: 'Lokasi Multipick',
  MULTIDROP_LOCATION: 'Lokasi Multidrop',
  NO_GRUP_CARGO: 'No Grup Kargo',
  NO_CARGO: 'No Kargo',
  TRANSPORTER: '3PL',
  DELIVERY_DATE_ORIGIN: 'Tgl Pengiriman',
  DELIVERY_DATE_DESTINATION: 'Tgl Berangkat',
  NO_AVIATION_SERVICE: 'No. Pelayanan Penerbangan',
  SHIPPER: 'Shipper',
  SHIPPER_INFO: 'Informasi Shipper',
  TRIP_INFO: 'Informasi Perjalanan',
  RUTE_INFO: 'Informasi Rute',
  VEHICLE_INFO: 'Informasi Kendaraan',
  MULTIMODA_INFO: 'Informasi Multi Moda',
  MODA_INFO: 'Informasi Moda',
  VEHICLE: 'Kendaraan',
  VEHICLE_NUMBER: 'No. Kendaraan',
  LABEL3PL: 'Rekanan 3PL',
  LAST_LOCATION: 'Lokasi Terakhir',
  STATUS: 'Status',
  SEARCHNO: 'Cari No Kiriman / Kargo',
  FILTER: 'Filter Berdasarkan',
  PAGE_NAME: 'Daftar Pesanan',
  MERGE: 'Gabungkan',
  HAVE_NOT_BEEN_ASSIGNED: 'Belum diassign satupun',
  PARTIALLY_ASSIGNED: 'Ada sebagian yang belum diassign',
  ASSIGN1: 'Assign / Ubah 3PL',
  CHANGE_RUTE: 'Ubah Rute',
  ADD_RUTE: 'Tambah Rute',
  SEND_DATE: 'Tanggal Pengiriman',
  TYPE_VEHICLE: 'Tipe Kendaraan',
  PICK_UP_LOCATION: 'Lokasi Penjemputan',
  LOCATION_IN_ACCEPTED: 'Lokasi Bongkar',
  MERGE_ORDER_LCL: 'Gabungkan Pesanan LCL',
  COMBINED_ORDERS: 'Pesanan yang sudah digabungkan',
  NOT_COMBINED_ORDERS: 'Pesanan belum digabungkan',
  TONASE: 'Tonase(Kg)',
  TONNAGE: 'Tonase(Kg)',
  KUBIKASI: 'Kubikasi(m3)',
  FILTER_PROVINSI_ORIGIN: 'Filter Provinsi Asal',
  FILTER_PROVINSI_DESTINATION: 'Filter Provinsi Tujuan',
  SELECT: 'Pilih',
  CANCEL_ORDER: 'Batalkan Pesanan',
  ACTION: 'Aksi',
  ASSIGN_MULTI_MODA: 'Assign Rekanan 3PL - Multi Moda',
  PLEASE_SELECT: 'Silahkan pilih terlebih dahulu',
  UPDATE_STATUS_LUMPSUM: 'Update Status (Lumpsum)',
  CHANGE_TRANSPORTER: 'Ubah Rekanan 3PL & Data Multi Moda',
  ALERT_DELETE_MULTIMODA: 'Apakah anda yakin ingin membatalkan Kiriman ini ?',
  NOTE: 'Catatan',
  CURRENT_NOW: 'Status Saat Ini',
  NEW_STATUS: 'Status Baru',
  BACKHAULING: 'Backhauling',
  TYPE_MODA: 'Tipe Moda',
  POD_DOCUMENT: 'Pod Document',
  TYPE_TRANSPORTER: 'Jenis Kendaraan',
  NO_TELP: 'No. Telp',
  DRIVER: 'Sopir',
  UPDATE: 'Ubah / Update Informasi',
  UPDATE_MULTIMODA: 'Update Informasi - Multi Moda',
  NO_CONTAINER: 'No. Kontainer',
  NO_CRUISE_FLIGHTS: 'No Pelayaran / Penerbangan',
  SHIP_NAME: 'Nama Kapal / Pesawat',
  CRUISE_NAME_MASKAPAI: 'Nama Pelayaran / Maskapai',
  ESTIMATION_OF_DEPARTURE: 'Estimasi Keberangkatan',
  ESTIMATION_ARRIVED: 'Estimasi Tiba',
  DEPATURE_REALIZATION: 'Realisasi Keberangkatan',
  REALIZATION_ARRIVES: 'Realisasi Tiba',
  PICKUP_DATE: 'Tanggal Bongkar',
  ADDITIONAL_INFORMATION: 'Informasi Tambahan',
  CONFIRM_REJECTED: 'Konfirmasi Penolakan',
  REASON: 'Alasan',
  FOOTER_LIST_VEHICLE: 'Daftar Kendaraan dan Sopir',
  SELECT_VEHICLE: 'Cari Nomor Kendaraan / Nama Sopir',
  LICENSE_PLATE: 'Nomor Kendaraan',
  SHIPPER_ABBREVIATION: 'Shipper Alias',
  QUANTITY_INFO: 'Informasi Quantity',
  RITASE: 'Ritase',
  KILO: 'Kilo',
  ONCALL: 'Oncall',
  BOX: 'Box',
  MASSA: 'Massa (Kg)',
  CUBIC: 'Kubikasi (m3)',
  BOX_CARTON: 'Box(Karton)',
  SHIPPER_NAME: 'Nama Shipper',
  NAME_SENDER: 'Nama Pengirim',
  CATEGORY: 'Kategori',
  SUB_CATEGORY: 'Sub Kategori',
  PHONE_NUMBER_SENDER: 'No Telepon Pengirim',
  ORDER_STATUS_LABEL: 'Status Pesanan',
  DOCUMENTATION: 'Dokumentasi',
  COMPLETED_DOCUMENT: 'Dokumen Lengkap',
  FOTO_LATTER: 'Foto Surat Jalan',
  ADD_DOCUMENT_FOTO: 'Tambah Foto Dokumen',
  LOCATION_MAP: 'Peta Lokasi Asal dan Tujuan Pesanan',
  COMPLATE_THE_ORDER: 'Selesaikan Pesanan',
  CANCEL_THE_ORDER: 'Batalkan Pesanan',
  CONFIRMED: 'Terkonfirmasi',
  CARGO_STATUS: 'Status Kargo',
  CALCULATE_COST: 'Hitung Biaya',
  MAIN_COST: 'Biaya Utama',
  ADDITIONAL_COST: 'Biaya Tambahan',
  AMOUNT_OF_COSTS: 'Jumlah Biaya (Rp)',
  TOTAL_COST: 'Total Biaya (Rp)',
  TOTAL_BILL: 'Total Tagihan (Rp)',
  GOODS_RECEIVED_WAREHOUSE: 'Barang Diterima Gudang',
  CALCULATE: 'Hitung',
  CHANGE_QUANTITY_ORDER: 'Mengubah quantity untuk order',
  NO_ROAD_LETTER: 'No Surat Jalan',
  QUANTITY: 'Quantity',
  UNIT: 'Unit',
  COMPLATED_THE_ORDER: 'Menyelesaikan Pesanan',
  LABEL_CANCEL_ORDER: 'Apakah anda yakin ingin membatalkan Pesanan ini?',
  OVERWRITE_CONFIRMATION_SHIPPER: 'Overwrite konfirmasi Shipper',
  LABEL_GOODS_RECEIVED_WAREHOUSE: 'Apakah anda yakin barang diterima Gudang?',
  UPLOAD_FOTO_DOCUMENTATION: 'Unggah Foto Dokumentasi',
  DOCUMENT_TYPE: 'Tipe Dokumen',
  DOCUMENT_PHOTOS: 'Dokumen Photo',
  DATA_CONFIRMATION_DRIVER: 'Data Konfirmasi Driver',
  ASSIGN_DATE: 'Tanggal Assign',
  ASSIGN_DRIVER: 'Assign Driver',
  NO_DATA_DRIVER: 'Data tidak ditemukan',
  ETA: 'Eta',
  STATUS_ORDER: {
    CANCEL: 'Dibatalkan',
    CONFIRM: 'Terkonfirmasi',
  },
  LOADING_TEXT: 'Loading... Please wait',
  CHANGE_DRIVER: 'Ubah Sopir',
  LIST_VEHICLE: 'Daftar Kendaraan',
  LIST_DRIVERS: 'Daftar Sopir',
  LABEL_ORIGIN: 'ORIGIN',
  LABEL_BACKHAULING: 'BACKHAULING',
  UPLOAD_DOCUMENT_POD: 'Upload Dokumen POD',
  REPORT_THE_PROBLEM: 'Laporkan Masalah',
  GOODS_HANDOVER: 'Pernyataan Serah Terima Barang',
  GOODS_CATEGORY: 'Kategori Barang',
  GOODS_NOTE: 'Catatan Barang',
  GOODS_SUB_CATEGORY: 'Sub Kategori Barang',
  RECEPIENTS_NAME: 'Nama Penerima',
  SIGNATURE: 'Tanda Tangan',
  DELIVERY_COMPLETE: 'Penerimaan Selesai',
  DAMAGE_REPORT: 'Laporan Kerusakan / Selisih',
  REPORT_DIFFERENCE: 'Laporkan Selisih',
  BASP_REASON: 'Alasan BASP',
  EXPLANATION_OF_REASON: 'Keterangan Alasan',
  EXPLANATION_OF_REASON_BASP: 'Alasan Keterangan BASP',
  TYPE_OF_GOODS: 'Jenis Barang',
  DESCRIPTION: 'Keterangan',
  PROOF_OF_DIFFERENCE_IN_ACCEPTANCE: 'Bukti Selisih Penerimaan',
  SAVE_AND_CONTINUE: 'Simpan dan Lanjutkan',
  CREATE_BAST: 'Berita Acara Serah Terima',
  SEND_BASP: 'Kirim BASP',
  UPLOAD_DOCUMENTATION: 'Upload Dokumentasi',
  UPLOAD_TRAVEL_DOCUMENT: 'Upload Foto Surat Jalan',
  UPLOAD_ROAD_COMMAND: 'Upload Surat Perintah Pengiriman',
  REPORT_DIFFERENCE_2: 'Lapor Selisih',
  STATUS_LIST_WAITING: [
    {
      text: 'Pesanan Dibuat',
      value: 'PESANAN_DIBUAT',
    },
    {
      text: 'Menunggu Konfirmasi',
      value: 'MENUNGGU_KONFIRMASI',
    },
    {
      text: 'Menunggu Konfirmasi Driver',
      value: 'MENUNGGU_KONFIRMASI_DRIVER',
    },
    {
      text: 'Menunggu Konfirmasi Transporter',
      value: 'MENUNGGU_KONFIRMASI_TRANSPORTER',
    },
    {
      text: 'Waktu Habis',
      value: 'WAKTU_HABIS',
    },
    {
      text: 'Driver Waktu Habis',
      value: 'DRIVER_WAKTU_HABIS',
    },
    {
      text: 'Kedaluwarsa',
      value: 'KEDALUWARSA',
    },
  ],
  STATUS_LIST_PROCESS: [
    {
      text: 'Terkonfirmasi',
      value: 'TERKONFIRMASI',
    },
    {
      text: 'Penjemputan',
      value: 'PENJEMPUTAN',
    },
    {
      text: 'Di Lokasi Penjemputan',
      value: 'DI_LOKASI_PENJEMPUTAN',
    },
    {
      text: 'Pengiriman',
      value: 'PENGIRIMAN',
    },
    {
      text: 'Di Lokasi Tujuan',
      value: 'DI_LOKASI_TUJUAN',
    },
    {
      text: 'Waktu Habis',
      value: 'WAKTU_HABIS',
    },
    {
      text: 'Menunggu Muat Barang',
      value: 'MENUNGGU_MUAT_BARANG',
    },
    {
      text: 'Muat Barang',
      value: 'MUAT_BARANG',
    },
    {
      text: 'Menyebrang Pengiriman',
      value: 'MENYEBRANG_PENGIRIMAN',
    },
    {
      text: 'Dalam Penerbangan',
      value: 'DALAM_PENERBANGAN',
    },
    {
      text: 'Dalam Penyebrangan',
      value: 'DALAM_PENYEBRANGAN',
    },
    {
      text: 'Sampai Bandara',
      value: 'SAMPAI_BANDARA',
    },
    {
      text: 'Sampai Pelabuhan',
      value: 'SAMPAI_PELABUHAN',
    },
    {
      text: 'Bongkar Muatan',
      value: 'BONGKAR_MUATAN',
    },
    {
      text: 'BAP - Konfirmasi Shipper',
      value: 'BA_PENERIMAAN_KONFIRMASI_SHIPPER',
    },
    {
      text: 'BAP - Diterima Gudang',
      value: 'BA_PENERIMAAN_DITERIMA_GUDANG',
    },
    {
      text: 'BAP - Kembali ke Gudang Asal',
      value: 'BA_PENERIMAAN_KEMBALI_KE_GUDANG_ASAL',
    },
  ],
  STATUS_LIST_COMPLETED: [
    {
      text: 'Diterima Utuh',
      value: 'BARANG_DITERIMA_UTUH',
    },
    {
      text: 'Diterima Tidak Utuh',
      value: 'BARANG_DITERIMA_TIDAK_UTUH',
    },
    {
      text: 'Dibatalkan',
      value: 'DIBATALKAN',
    },
    {
      text: 'Kedaluwarsa',
      value: 'KEDALUWARSA',
    },
    {
      text: 'Ditolak',
      value: 'DITOLAK',
    },
    {
      text: 'Dokumen Terkirim',
      value: 'DOKUMEN_TERKIRIM',
    },
    {
      text: 'Dokumen Lengkap',
      value: 'DOKUMEN_LENGKAP',
    },
    {
      text: 'BAP - Diterima Gudang',
      value: 'BA_PENERIMAAN_DITERIMA_GUDANG',
    },
    {
      text: 'BAP - Kembali ke Gudang Asal',
      value: 'BA_PENERIMAAN_KEMBALI_KE_GUDANG_ASAL',
    },
    {
      text: 'BAP - Ganti Lokasi Tujuan',
      value: 'BA_PENERIMAAN_GANTI_LOKASI_TUJUAN',
    },
  ],
  REPORT_DELIVERY_PROBLEMS: 'Laporkan Masalah Pengiriman',
  LATE_CAUSE: 'Yang Menyebabkan Pengiriman Terlambat',
  ADDRESS_LOCATION: 'Lokasi Alamat',
  POSITION: 'Posisi',
  PROOF_OF_PROBLEM: 'Bukti Masalah',
  SHIPPING_COST: 'Biaya Ongkos Kirim',
  LIST_SHIPPING_COST: 'Daftar Biaya Ongkos Kirim',
  LIST_ADDITIONAL_COST: 'Daftar Biaya Tambahan',
  COST_NAME: 'Nama Biaya',
  COST: 'Biaya',
  ACTION_TIME: 'Waktu Aksi',
  ADDITIONAL_NOTE: 'Catatan Tambahan',
  SENDER_NAME: 'Nama Pengirim',
  SENDER_NOTE: 'Catatan Pengiriman',
  ORDER_DETAIL: 'Detail Kiriman',
};
