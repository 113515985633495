import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const baspList = async (filter) => {
  try {
    const res = await axios.get(
      `${urls.baspList}?${querystring.stringify(filter)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const baspDetail = async (baspId) => {
  try {
    const res = await axios.get(`${urls.baspDetail}/${baspId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export {
  baspList,
  baspDetail,
};
