export default {
  FULL_NAME: 'Nama Lengkap',
  EMAIL: 'Email',
  EMAIL_HINT: 'Contoh: email@fastlog.id',
  PASSWORD: 'Kata Sandi',
  REPEAT_PASSWORD: 'Konfirmasi Kata Sandi',
  LOGIN: 'Masuk',
  ACTIVATE_ACCOUNT: 'Aktivasi Akun',
  USERNAME: 'Nama Lengkap',
  FORGOT_PASSWORD: 'Lupa Kata Sandi?',
  CREATE_ACCOUNT: 'Belum punya akun?',
  REGISTER: 'Daftar Disini',
  REGISTRATION: 'Daftar',
  CONTINUE_REGISTRATION: 'Lanjut Registrasi',
  TELP: 'No. Telepon',
  INFO_REGISTRATION: 'Setelah klik Daftar maka sistem akan mengirimkan email link aktivasi ke email anda. Silahkan klik link dari email tersebut untuk melakukan aktivasi akun anda.',
  ISAGREE: 'Saya sudah membaca dan setuju dengan',
  TERMS: 'Syarat dan Ketentuan',
};
