/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getDetailMou = async () => {
  try {
    const res = await axios.get(`${urls.mou}/0`);
    return res?.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getListTransportTypeLimit = async (mouId, transportTypeLimitId, filters, source) => {
  try {
    const res = await axios.get(`${urls.mou}/${mouId}/transport-type-limit/${transportTypeLimitId}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res?.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getListOldTransportTypeLimit = async (mouId, filters) => {
  try {
    const res = await axios.get(`${urls.mouTransporterRoute}/${mouId}/transport-type-limit/old?${querystring.stringify(filters)}`);
    return res?.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getListLincesePlate = async (filters, source) => {
  try {
    const res = await axios.get(`${urls.transportList}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const saveTransportLimit = async (mouId, transportTypeLimitId, form) => {
  try {
    const res = await axios.post(`${urls.mou}/${mouId}/transport-type-limit/${transportTypeLimitId}`, form);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getDetailWorkflowMou = async (mouId) => {
  try {
    const res = await axios.get(`${urls.detailWorkflowMou(mouId)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchHistoryWorkFlow = async (workflowId) => {
  try {
    const res = await axios.get(`${urls.contractHistory}/${workflowId}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const approveInProcessMou = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxAppoveMou}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const reviseInProcessMou = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxReviseMou}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const rejectInProcessMou = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxRejectMou}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const donwloadMou = async (mouId) => {
  try {
    const res = await axios.get(`${urls.mou}/${mouId}/download/document`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getCooperationInformation = async () => {
  try {
    const res = await axios.get(`${urls.mouTransporterRoute}/company`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouLocationByServiceType = async (filters, { mouId, serviceType }) => {
  try {
    const res = await axios.get(`${urls.mouTransporterRoute}/${mouId}/route/${serviceType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouRoute = async (filters, { mouId, serviceType, originId }) => {
  try {
    if (originId) filters.originId = originId;
    const res = await axios.get(`${urls.mouTransporterRoute}/${mouId}/route/${serviceType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getOldMouRoute = async (mouId, serviceType, filters) => {
  try {
    const res = await axios.get(
      `${urls.mouTransporterRoute}/${mouId}/route/${serviceType}/old?${querystring.stringify(
        filters,
      )}`,
    );
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouLocation = async (filters, { mouId }) => {
  try {
    const res = await axios.get(`${urls.mouTransporterRoute}/${mouId}/location?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouTransportType = async (filters, { mouId }) => {
  try {
    const res = await axios.get(`${urls.mouTransporterRoute}/${mouId}/transport-type?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouTransportTypeLimit = async (filters, { mouId }) => {
  try {
    const res = await axios.get(`${urls.mouTransporterRoute}/${mouId}/transport-type-limit?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getOriginLocation = async (filters, { serviceType, transportTypeId }) => {
  try {
    if (transportTypeId) filters.transportTypeId = transportTypeId;
    const res = await axios.get(`${urls.shipperRoute}/${serviceType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  getDetailWorkflowMou,
  fetchHistoryWorkFlow,
  getMouRoute,
  approveInProcessMou,
  reviseInProcessMou,
  rejectInProcessMou,
  getDetailMou,
  getListTransportTypeLimit,
  saveTransportLimit,
  getListLincesePlate,
  donwloadMou,
  getCooperationInformation,
  getMouLocation,
  getMouTransportType,
  getMouTransportTypeLimit,
  getMouLocationByServiceType,
  getOriginLocation,
  getListOldTransportTypeLimit,
  getOldMouRoute,
};
