export default {
  PAGE_TITLE: 'P2P Lending',
  CREATE_NEW: 'Buat Baru',
  NO_P2P_LENDING: 'No P2P Lending',
  NOTE: 'Catatan',
  P2P_LENDING_APPROVAL: 'Persetujuan P2P Lending',
  STATUS_PAYMENT: 'Status Pembayaran',
  PAYMENT_DATE: 'Tanggal Pembayaran',
  PROOF_OF_PAYMENT: 'Bukti Pembayaran',
  INVOICE: 'Invoice',
  NO_INVOICE: 'No Invoice',
  CREATED_AT: 'Tanggal Dibuat',
  INITIAL_INVOICE: 'Invoice (Awal)',
  INVOICE_P2P_LENDING: 'Invoice (P2P Lending)',
  DUE_DATE: 'Tanggal Jatuh Tempo',
  INVOICE_DATE: 'Tanggal Invoice',
  CREATED_BY: 'Dibuat Oleh',
  STATUS: 'Status',
  ACTION: 'Aksi',
  LIST_P2P_LENDING: 'Daftar P2P Lending',
  CREATE_P2P_LENDING: 'Buat P2P Lending',
  TOTAL_P2P_LENDING: 'Total P2P Lending',
  DELETE_INVOICE: 'Hapus Invoice',
  PERIOD_ORDER: 'Periode Pesanan',
  TOTAL_BILL: 'Total Tagihan',
  TOTAL_INVOICE: 'Total Invoice',
  TOP_P2P_LENDING: 'Term Of Payment - P2P Lending',
  TOP_P2P_LENDING_2: 'TOP - P2P Lending',
  CALCULATOR: 'Kalkulator',
  TOTAL_PAYMENT_TO_3PL: 'Total Pembayaran ke 3PL',
  TOP: 'Term Of Payment',
  ADMIN_FEES: 'Biaya Admin',
  INTEREST_COST: 'Biaya Bunga',
  INITIAL_TOP: 'TOP Awal',
  UPLOAD_INVOICE: 'Upload Invoice',
  UPLOAD_TRAVEL_LETTER: 'Upload Surat Jalan',
  UPLOAD_BAST: 'Upload BAST',
  UPLOAD_ADDITIONAL_DOCUMENT: 'Upload Dokumen Tambahan',
  INITAL_INVOICE_DUE_DATE: 'Invoice Due Date Awal',
  P2P_LENDING_DUE_DATE: 'P2P Lending Due Date',
  DOCUMENT_SUPPORT: 'Dokumen Pendukung',
  SELECT_INVOICE: 'Pilih Invoice',
  SUMMARY: 'Summary',
  NEXT: 'Lanjutkan',
  SAVE: 'Simpan',
  SUBMIT: 'Submit',
  ERROR_MESSAGE_DOCUMENTS: 'Terjadi Kesalahan Saat Memuat Dokumen',
  ERROR_MESSAGE: 'Terjadi Kesalahan Saat Memuat',
};
