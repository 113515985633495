import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const fetchContractDetailById = async (companyId) => {
  try {
    const res = await axios.get(`${urls.contractDetail}/${companyId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCompanyListExistsContract = async (companyName, companyType) => {
  try {
    const filters = {
      page: 0,
      size: 50,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.contractCompanyList}/contract/exists/${companyType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchCompanyListContract = async (companyName, companyType) => {
  try {
    const filters = {
      page: 0,
      size: 50,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.contractCompanyList}/contract/${companyType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  fetchContractDetailById,
  fetchCompanyListContract,
  fetchCompanyListExistsContract,
};
