/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import auth from './modules/auth.js';
import user from './modules/user';
import loading from './modules/loading';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    user,
    loading,
  },
});
