<template>
  <div>
    <v-text-field
      type="text"
      v-model="displayValue"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
  </div>
</template>

<script>
import IMask from 'imask';

const masked = IMask.createMask({
  mask: '+62 000-0000-000000',
});
export default {
  name: 'PhoneNumberInput',
  props: {
    value: {},
  },
  data() {
    return {
      localValue: '',
      isInputActive: false,
    };
  },
  computed: {
    displayValue: {
      get() {
        if (this.value == null) return '';
        masked.unmaskedValue = this.value;
        return masked.value;
      },
      set(modifiedValue) {
        masked.value = modifiedValue;
        this.$emit('input', masked.unmaskedValue);
      },
    },
  },
};
</script>

<style lang="scss">

</style>
