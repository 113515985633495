export default {
  pageName: 'Invoice Data',
  imageUrlLabel: 'Gambar',
  nameBankLabel: 'Nama Bank',
  aCLabel: 'A/C',
  aNLabel: 'A/N',
  signerOneLabel: 'Penanda Tangan 1',
  signatoryOneLabel: 'Jabatan Penanda Tangan 1',
  signerTwoLabel: 'Penanda Tangan 2',
  signatoryTwoLabel: 'Jabatan Penanda Tangan 2',
};
