import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * get order list
 * @param {Object} filter list parameters
 * @param {Number} filter.page page number
 * @param {Number} filter.size page size
 */
const orderList = async (filter, status, source) => {
  try {
    const res = await axios.get(`${urls.orderList}/filter/${status}?${querystring.stringify(filter)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get order by order id
 * @param {Number} shipmentId shipment id
 */
const getOrderById = async (shipmentId, source) => {
  try {
    const res = await axios.get(`${urls.orderList}/${shipmentId}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const historyStatus = async (shipmentCargoId, source) => {
  try {
    const res = await axios.get(`${urls.orderList}/${shipmentCargoId}/status`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const orderDocument = async (shipmentCargoId, source) => {
  try {
    const res = await axios.get(`${urls.orderList}/${shipmentCargoId}/document-pod/images`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const shipmentDetail = async (shipmentCargoId, source) => {
  try {
    const result = await axios.get(`${urls.orderList}/${shipmentCargoId}`, {
      cancelToken: source.token,
    });
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const assignmentConfirmation = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.patch(`${urls.orderList}/${shipmentCargoId}/confirmation`, data, {
      cancelToken: source.token,
    });
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const assignMultimoda = async (data) => {
  try {
    const res = await axios.put(`${urls.orderList}/${data.id}/multimoda`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMultimodaInfo = async (id) => {
  try {
    const res = await axios.get(`${urls.orderList}/${id}/multimoda`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDriverConfirmationList = async (shipmentCargoId) => {
  try {
    const res = await axios.get(`${urls.orderList}/${shipmentCargoId}/assign`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveDriver = async (form, source) => {
  try {
    const res = await axios.put(`${urls.orderList}/assign/driver`, form, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListQty = async (shipmentCargoId, shipmentId, isDelivery) => {
  try {
    let url = `${urls.orderList}/${shipmentCargoId}/quantity/${shipmentId}`;
    if (isDelivery) url = `${urls.orderList}/${shipmentCargoId}/quantity/delivery/${shipmentId}`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMainCost = async (shipmentCargoId) => {
  try {
    const res = await axios.get(`${urls.orderTransaction}/${shipmentCargoId}/main-cost`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUnitCost = async (shipmentCargoId) => {
  try {
    const res = await axios.get(`${urls.orderTransaction}/${shipmentCargoId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUnitPrice = async (shipmentCargoId) => {
  try {
    const res = await axios.get(`${urls.orderList}/${shipmentCargoId}/quantity/unit-price`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveQty = async (shipmentCargoId, form, isDelivery) => {
  try {
    let url = `${urls.orderList}/${shipmentCargoId}/quantity/${form.unit}`;
    if (isDelivery) url = `${urls.orderList}/${shipmentCargoId}/quantity/delivery/${form.unit}`;
    const res = await axios.put(url, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveCost = async (shipmentCargoId, form) => {
  try {
    const res = await axios.put(`${urls.orderTransaction}/${shipmentCargoId}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getVehicle = async (filters) => {
  try {
    const res = await axios.get(`${urls.transportList}/assign?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDrivers = async (filters) => {
  try {
    const res = await axios.get(`${urls.driverList}/assign?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListAdditionalCost = async (filters) => {
  try {
    const { name } = filters;
    filters = {
      ...filters,
      type: 'qIn(cost)',
      sort: 'name,desc',
      isActive: 'qistrue',
    };
    if (name) filters.name = `qLike(${name})`;
    const res = await axios.get(`${urls.selectionInfo}/filter?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveDriverChange = async (data) => {
  try {
    const res = await axios.patch(urls.saveDriverChange, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const baspReason = async (filters) => {
  try {
    const res = await axios.get(`${urls.selectionInfo}/filter?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const submitBasp = async (data, cargoId) => {
  try {
    const res = await axios.post(`${urls.orderList}/${cargoId}/basp`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadDocumentPod = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.post(`${urls.orderList}/${shipmentCargoId}/document-pod/upload`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateCargoOrderStatus = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.patch(`${urls.orderList}/${shipmentCargoId}/status/cargo`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateShipmentOrderStatus = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.patch(`${urls.orderList}/${shipmentCargoId}/status`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const bast = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.patch(`${urls.orderList}/${shipmentCargoId}/lumpsum/bast`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const basp = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.post(`${urls.orderList}/${shipmentCargoId}/lumpsum/basp`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const reportOrderAccident = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.post(`${urls.orderList}/${shipmentCargoId}/lumpsum/accident`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const masterShippingCost = async (shipmentCargoId, source) => {
  try {
    const res = await axios.get(`${urls.orderList}/${shipmentCargoId}/shipping-cost`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const additionalCost = async (shipmentCargoId, source) => {
  try {
    const res = await axios.get(`${urls.orderList}/${shipmentCargoId}/additional-shipping-cost`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateShippingCost = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.put(`${urls.orderList}/${shipmentCargoId}/shipping-cost`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveAdditionalCost = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.put(`${urls.orderList}/${shipmentCargoId}/additional-shipping-cost`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateAdditionalCost = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.patch(`${urls.orderList}/${shipmentCargoId}/additional-shipping-cost`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const orderExists = async () => {
  try {
    const res = await axios.get(urls.waitingShipment);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  orderList,
  getOrderById,
  shipmentDetail,
  assignmentConfirmation,
  assignMultimoda,
  getMultimodaInfo,
  getDriverConfirmationList,
  saveDriver,
  getListQty,
  getMainCost,
  getUnitPrice,
  saveQty,
  saveCost,
  getUnitCost,
  getVehicle,
  getDrivers,
  saveDriverChange,
  getListAdditionalCost,
  baspReason,
  submitBasp,
  historyStatus,
  orderDocument,
  updateCargoOrderStatus,
  updateShipmentOrderStatus,
  uploadDocumentPod,
  bast,
  basp,
  reportOrderAccident,
  masterShippingCost,
  additionalCost,
  updateShippingCost,
  saveAdditionalCost,
  updateAdditionalCost,
  orderExists,
};
