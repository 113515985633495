export const typeList = [
  {
    type: 'BASP',
    label: 'Ba Penerimaan',
  },
  {
    type: 'CANCELLATION',
    label: 'Alasan pembatalan',
  },
  {
    type: 'ACCIDENT',
    label: 'Kecelakaan',
  },
];

const getTypeLabel = (type) => typeList.find((i) => i.type === type).label || '';

export default {
  button: {
    ADD_OPTION: 'Tambah Pilihan',
    UPLOAD_CSV: 'Upload CSV',
    TEMPLATE_CSV: 'Template CSV',
    STATUS: 'Status data yang sudah diupload',
    ADD: 'Tambah Alasan',
  },
  label: {
    SEARCHING_PLACEHOLDER: 'Cari Pilihan dan Alasan',
    TITLE: 'Database Pilihan dan Alasan',
    CREATE_ENTRY: 'Buat Entri Pilihan dan Alasan',
    UPLOADED: 'Status data yang telah diunggah',
    REASON_FOR_CANCELLATION: 'Alasan Pembatalan',
    ACCIDENT_DESC: 'Keterangan Kecelakaan',
  },
  headers: {
    ITEM: 'Item',
    TYPE: 'Tipe',
    NEED_CONFIRM: 'Butuh Konfirmasi',
    FREE_TEXT: 'Free Text',
    ACTIVE: 'Aktif',
    CREATED_BY: 'Dibuat Oleh',
    UPLOAD_BY: 'Diunggah Oleh',
    UPLOAD_AT: 'Diunggah Pada',
    STATUS: 'Status Proses',
    DESCRIPTION: 'Deskripsi',
    SUCCESS: 'Sukses',
    FAILED: 'Gagal',
  },
  typeList,
  getTypeLabel,
};
