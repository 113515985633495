import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * get shipping problem list
 * @param {Object} filter list parameters
 * @param {Number} filter.page page number
 * @param {Number} filter.size page size
 */
const getListShippingProblem = async (filter, status) => {
  try {
    const res = await axios.get(`${urls.shippingProblemList}/${status}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDetailShippingProblem = async (id) => {
  try {
    const res = await axios.get(`${urls.shippingProblemProsesDetail}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const shippingProblemProses = async (accidentId) => {
  try {
    const res = await axios.patch(`${urls.shippingProblemProses(accidentId)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const shippingProblemDone = async (accidentId, form) => {
  try {
    const res = await axios.patch(`${urls.shippingProblemResolved(accidentId)}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const accidentExists = async () => {
  try {
    const res = await axios.get(`${urls.accident}/exists`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListShippingProblem,
  getDetailShippingProblem,
  shippingProblemProses,
  shippingProblemDone,
  accidentExists,
};
