export default {
  searchTransporter: 'Masukkan Nama Vendor',
  checkboxTransporter: 'Transporter',
  DATA_ARMADA: {
    title: 'Laporan Data Armada Per Status 3PL',
    pageName: 'Daftar Laporan Data Armada',
    dimension: 'Dimensi',
    year: 'Tahun',
    kirExpired: 'Masa Berlaku KIR',
    stnkExpired: 'Masa Berlaku STNK',
    note: 'Catatan',
  },
  DATA_DRIVER: {
    title: 'Laporan Data Driver Per Status 3PL',
    pageName: 'Daftar Laporan Data Driver',
    driverName: 'Nama Sopir',
    email: 'Alamat Email',
    noTelp: 'Nomor Telepon',
    noSIM: 'Nomor SIM',
    simExpired: 'Masa Berlaku SIM',
    dateVaccine1: 'Tanggal Vaksin Pertama',
    dateVaccine2: 'Tanggal Vaksin Kedua',
    typeVaccine: 'Jenis Vaksin',
  },
  SHIPPING_PROBLEM: {
    title: 'Laporan Masalah Armada Pengiriman',
    noShipment: 'Nomor Kiriman',
    nameShipper: 'Nama Shipper',
    dateOrder: 'Tanggal Pesanan',
    datePickup: 'Tanggal Pickup',
    originalLocation: 'Lokasi Asal',
    destinationLocation: 'Lokasi Tujuan',
    nameTransport: 'Nama 3PL',
    noVehicle: 'No.Kendaraan',
    typeVehicle: 'Tipe Kendaraan',
    merkVehicle: 'Merk Kendaraan',
    driver: 'Sopir',
    problem: 'Masalah',
    dateReport: 'Tanggal Pelaporan',
    locateCoordinate: 'Lokasi(Koordinat)',
    solution: 'Solusi',
    dateFix: 'Tanggal Penyelesaian',
    status: 'Status',
    namePIC: 'PIC yang menangani',
    pageName: 'Daftar Laporan Pesanan',
  },
  ORDER_SHIPPER: {
    title: 'Laporan Pesanan Shipper per Status',
    noOrder: 'Nomor Pesanan',
    name3PL: '3PL',
    dateOrderMade: 'Tanggal Pesanan Dibuat',
    dateAutoAssign: 'Tanggal Auto Assign Ke 3PL',
    dateAutoAssignDriver: 'Tanggal Auto Assign Ke Driver',
    dateOrderConfirmation: 'Tanggal Pesanan Telah Terkonfirmasi',
    datePickUp: 'Tanggal Penjemputan',
    dateFinishOrderPickUp: 'Tanggal Sampai Lokasi Penjemputan',
    dateDelivery: 'Tanggal Pengiriman',
    dateArrivalDestination: 'Tanggal Sampai Lokasi Tujuan',
    dateReceipt: 'Tanggal Terima Barang',
    datePOD: 'Tanggal Kirim POD',
    datePODComplete: 'Tanggal Terima Lengkap POD',
    datePODDelivery: 'Tanggal Kirim Fisik POD Ke Shipper',
    statusDelivery: 'Status Pengiriman',
    solutionMethod: 'Metode Penyelesaian',
    manualPodReason: 'Alasan POD Manual',
    pageName: 'Daftar Laporan Pesanan',
  },
  MISMATCH_BASP: {
    title: 'Laporan Selisih Penerimaan Barang (BASP)',
    dateBasp: 'Tanggal BASP',
    reasonBasp: 'Alasan BASP',
    category: 'Kategori',
    subCategory: 'Sub Kategori',
    itemDescription: 'Alasan Keterangan BASP',
    confirmShipper: 'Konfirmasi Shipper',
    description: 'Keterangan',
    pageName: 'Daftar BASP',
  },
  AGING_FLI: {
    title: 'Laporan Aging Piutang ke Fastlog',
    noInvoice: 'No Invoice',
    dateInvoiceCreate: 'Tanggal Invoice Dibuat',
    dateInvoiceAccept: 'Tanggal Invoice Diterima',
    top: 'TOP (Hari)',
    dateTempo: 'Tanggal Piutang Jatuh Tempo',
    aging: 'Aging',
    typeCost: 'Jenis Biaya',
    current: 'Current',
    day7: '1-7 Hari',
    day15: '8-15 Hari',
    day30: '16-30 Hari',
    day60: '31-60 Hari',
    day90: '61-90 Hari',
    dayBigger90: '> 90 Hari',
    amountNet: 'Jumlah (Net)',
    pageName: 'Daftar Laporan Aging',
  },
  ESTIMATION_INCOME: {
    title: 'Laporan Estimasi Pendapatan',
    pageName: 'Daftar Estimasi Pendapatan',
    typeContact: 'Jenis Kontrak',
    amount: 'Jumlah',
  },
  TONASE_CUBIC: {
    title: 'Laporan Pengiriman by Kubikasi & Tonase',
    pageName: 'Daftar Pengiriman',
    name3PL: 'Nama 3PL',
    amountCubic: 'Jumlah Kubikasi (m3)',
    amountTonase: 'Jumlah Tonase (ton)',
    amountCarton: 'Jumlah Karton',
  },
  AGING_POD: {
    title: 'Laporan Aging POD',
    pageName: 'Daftar Laporan',
    noDeliveryOrder: 'Nomor Surat Jalan',
    dateReceipt: 'Tanggal Serah Terima Barang',
    dateReceivePOD: 'Tanggal Penerimaan Fisik POD',
    leadTime: 'Leadtime POD (hari)',
    target: 'Target',
  },
  COLLECTION: {
    title: 'Laporan Collection',
    pageName: 'Daftar Laporan',
    dateDebt: 'Tanggal Piutang',
    nominal: 'Nominal',
    date: 'Tanggal',
    nominalPayment: 'Nominal Pembyaran',
    remainDebt: 'Sisa Piutang',
  },
  PERFORMANCE: {
    title: 'Laporan Performance 3PL',
    titleDriver: 'Laporan Performance Driver',
    pageName: 'Daftar Laporan Performance',
    comPrice: 'Competitive Price',
    truckAvail: 'Truck Availability',
    otDelivery: 'On Time Delivery',
    basp: 'BASP',
    otDeparture: 'On Time Departure',
    otfPOD: 'On Time Fisik POD',
    score: 'Total Score',
  },
  SHIPING_COST: {
    title: 'Laporan Biaya Ongkos Kirim',
    pageName: 'Daftar Laporan Biaya Ongkos Kirim',
    bbmCost: 'Biaya BBM',
    tollCost: 'Biaya Toll',
    parkingCost: 'Biaya Parkir',
    foodCost: 'Biaya Uang Makan',
    insentCost: 'Biaya Insentif',
    contributeCost: 'Biaya Kontribusi',
    loadCost: 'Biaya Muat',
    unloadCost: 'Biaya Bongkar',
    etcCost: 'Biaya Lain-Lain',
    amountCost: 'Total Biaya',
  },
  TAX: {
    title: 'Laporan Pajak',
    pageName: 'Daftar Laporan Pajak',
    factureName: 'Nama Faktur',
    noReceipt: 'No Kuitansi',
    dateInvoice: 'Tgl Invoice',
    currency: 'Mata Uang',
    dpp: 'DPP',
    ppn: 'PPN',
    pph: 'PPH Ps 23',
    amount: 'Total',
    descInvoice: 'Keterangan Invoice',
  },
  REPORT_LIST: 'Daftar Laporan',
  SELECT_THE_REPORT: 'Pilih laporan yang ingin anda lihat',
  NEXT: 'Selanjutnya',
};
