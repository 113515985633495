/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import * as Strings from '../assets/strings';

const Welcome = () => import(/* webpackChunkName: "view-Welcome" */ '../views/Welcome.vue');
const Login = () => import(/* webpackChunkName: "component-WelcomeLogin" */ '../components/Welcome/Login.vue');
const ActivationAccount = () => import(/* webpackChunkName: "component-WelcomeActivation" */ '../components/Welcome/Activation.vue');
const ActivationSubAccount = () => import(/* webpackChunkName: "component-WelcomeActivation" */ '../components/Welcome/SubActivation.vue');
const ForgotPassword = () => import(/* webpackChunkName: "component-ForgotPassword" */ '../components/Welcome/ForgotPassword.vue');
const ResetPassword = () => import(/* webpackChunkName: "component-ResetPassword" */ '../components/Welcome/ResetPassword.vue');

const MainContainer = () => import(/* webpackChunkName: "MainContainer" */ '../views/MainContainer');

const DashboardPage = () => import(/* webpackChunkName: "view-Dashboard" */ '../views/Dashboard');

const AboutPage = () => import(/* webpackChunkName: "view-About" */ '../views/About');
const ContactPage = () => import(/* webpackChunkName: "view-About" */ '../views/ContactUs');
const PrivacyPage = () => import(/* webpackChunkName: "view-PrivacyPage" */ '../views/PrivacyPolicy');

const ShipmentsPage = () => import(/* webpackChunkName: "view-Dashboard" */ '../views/Shipments');

const Registration = () => import(/* webpackChunkName: "component-CustomerList" */ '../components/Welcome/Registration.vue');

const UserAdminPage = () => import(/* webpackChunkName: "view-UserAdmin" */ '../views/UserAdmin.vue');
const UserAdminList = () => import(/* webpackChunkName: "component-UserAdminList" */ '../components/UserAdmin/List');
const UserAdminCreate = () => import(/* webpackChunkName: "component-UserAdminCreate" */ '../components/UserAdmin/Create');

const CompanyRegistrationPage = () => import(/* webpackChunkName: "view-CompanyRegistration" */ '../views/CompanyRegistration.vue');
const DocumentSupportCompany = () => import(/* webpackChunkName: "view-DocumentSupportCompany" */ '../views/DocumentSupportCompany.vue');

// Legal Document
const LegalDocumentPage = () => import(/* webpackChunkName: "view-LegalDocumentPage" */ '../views/LegalDocument.vue');
const LegalDocumentList = () => import('../components/LegalDocument/List.vue');

// Order
const OrderPage = () => import(/* webpackChunkName: "view-OrderPage" */ '../views/Orders.vue');
const OrderList = () => import(/* webpackChunkName: "component-OrderList" */ '../components/Orders');
const OrderDetail = () => import(/* webpackChunkName: "component-OrderDetail" */ '../components/Orders/OrderDetails');
// const OrderDetail = () => import(/* webpackChunkName: "component-OrderDetail" */ '../components/Orders/OrderDetail');

// Approval
const InboxApprovalPage = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../views/ApprovalInbox.vue');
const InboxApprovalList = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../components/Approval/Inbox');
const ApprovalInProcessPage = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../views/ApprovalInProcess.vue');
const ApprovalInProcessList = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../components/Approval/InProcess');
const CompletedApprovalPage = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../views/CompleteApproval.vue');
const CompletedApprovalList = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../components/Approval/Completed');

const StatusApprovalPage = () => import(/* webpackChunkName: "view-StatusApproval" */ '../views/StatusApproval.vue');
const StatusApprovalList = () => import(/* webpackChunkName: "component-StatusApprovalList" */ '../components/CompanyRegistration/StatusRegistration.vue');

// Transport
const TransportPage = () => import('../views/Transport.vue');
const TransportList = () => import('../components/Transport/List.vue');
const TransportCreate = () => import('../components/Transport/Create.vue');
// Driver
const DriverPage = () => import('../views/Driver.vue');
const DriverList = () => import('../components/Driver/List.vue');
const DriverCreate = () => import('../components/Driver/Create.vue');

// Invoice Data
const InvoiceDataPage = () => import(/* webpackChunkName: "view-LegalDocumentPage" */ '../views/InvoiceData.vue');
const InvoiceDataList = () => import('../components/InvoiceData/Form.vue');

// Invoice fixed price
const InvoiceFixedPrice = () => import(/* webpackChunkName: "fixed-price-invoice" */ '../views/InvoiceFixedPrice.vue');
const InvoiceFixedPriceList = () => import(/* webpackChunkName: "fixed-price-invoice-list" */ '../components/InvoiceFixedPrice/List.vue');
const InvoiceFixedPriceListDetail = () => import(/* webpackChunkName: "fixed-price-invoice-list-detail" */ '../components/InvoiceFixedPrice/ListDetail.vue');
const InvoiceCreateFixedPrice = () => import(/* webpackChunkName: "create-fixed-price-invoice" */ '../components/InvoiceFixedPrice/Create.vue');

// Postage Cost
const PostageCostPage = () => import('../views/PostageCost.vue');
const PostageCostList = () => import('../components/PostageCost/List.vue');

// Email
const Email = () => import(/* webpackChunkName: "view-AdditionalCost" */ '../views/Email.vue');
const EmailList = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../components/Email/List.vue');

// MOU
const MouApproval = () => import('../components/MouInProcess');

const MoUPage = () => import(/* webpackChunkName: "view-MoUPage" */ '../views/MoU.vue');
const MouDetailIndex = () => import(/* webpackChunkName: "component-MouDetailIndex" */ '../components/MoU');
const MoUDetail = () => import(/* webpackChunkName: "component-MoUDetail" */ '../components/MoU/Detail');

// ShippingProblem
const ShippingProblemPage = () => import(/* webpackChunkName: "view-ShippingProblemPage" */ '../views/ShippingProblem');
const ShippingProblemList = () => import(/* webpackChunkName: "component-ShippingProblemList" */ '../components/ShippingProblem');
const ShippingProblemDetail = () => import(/* webpackChunkName: "component-OrderDetail" */ '../components/ShippingProblem/ShippingProblemDetail');

// Damage And Missing
const DamageAndMissing = () => import(/* webpackChunkName: "view-LegalDocumentPage" */ '../views/DamageAndMissing.vue');
const DamageAndMissingList = () => import('../components/DamageAndMissing/List.vue');

// Invoice
const Invoice = () => import(/* webpackChunkName: "view-Invoice" */ '../views/Invoice.vue');
const InvoiceList = () => import(/* webpackChunkName: "component-InvoiceList" */ '../components/Invoice/List.vue');
const InvoiceCreate = () => import(/* webpackChunkName: "component-InvoiceCreate" */ '../components/Invoice/Create.vue');
const InvoiceEdit = () => import(/* webpackChunkName: "component-InvoiceEdit" */ '../components/Invoice/Edit.vue');

// AccountInfo
const AccountInfo = () => import(/* webpackChunkName: "view-AccountInfo" */ '../views/AccountInfo.vue');
const AccountInfoGet = () => import(/* webpackChunkName: "component-AccountInfoGet" */ '../components/AccountInformation/List.vue');

// Schedule
const SchedulePage = () => import(/* webpackChunkName: "view-SchedulePage" */ '../views/Schedule');
const ScheduleList = () => import(/* webpackChunkName: "view-ScheduleList" */ '../components/Schedule');

// Workflow
const SkeletonLoadersWorkflow = () => import('../views/SkeletonLoadersWorkflow.vue');

// Report
const ReportPage = () => import(/* webpackChunkName: "view-ReportPage" */ '../views/Report.vue');
const ReportList = () => import(/* webpackChunkName: "component-ReportList" */ '../components/Report');
const ReportFilters = () => import(/* webpackChunkName: "component-ReportList" */ '../components/Report/ReportFilters');
const ReportDetail = () => import(/* webpackChunkName: "component-ReportList" */ '../components/Report/Detail');

// POD Multimoda
const PagePodDocument = () => import(/* webpackChunkName: "page-document-multimoda" */'../views/PodDocument.vue');
const ListPodDocument = () => import(/* webpackChunkName: "list-dokumen-pod" */ '../components/PodDocument/List.vue');
const ListDetailPodDocument = () => import(/* webpackChunkName: "list-dokumen-pod" */ '../components/PodDocument/ListDetails.vue');

// Damage Report
const formDamageReport = () => import(/* webpackChunkName: "form-damage-report" */ '../components/DamageReport/Form.vue');

// P2P Lending
const PageP2PLending = () => import(/* webpackChunkName: "page-p2p-lending" */'../views/P2PLending.vue');
const ListP2PLending = () => import(/* webpackChunkName: "list-p2p-lending" */ '../components/P2PLending/List.vue');
const CreateP2PLending = () => import(/* webpackChunkName: "create-p2p-lending" */ '../components/P2PLending/Create.vue');

// Contract
const Contract3PLPage = () => import(/* webpackChunkName: "view-Contract3PLPage" */ '../views/Contract3PL');
const ContractForm = () => import(/* webpackChunkName: "component-ContractForm" */ '../components/Contract');

Vue.use(VueRouter);

// guard for logged in user to not open welcome page
const loginPageGuard = (to, from, next) => {
  if (store.getters['auth/isLoggedIn']) {
    next('/main');
  } else {
    next();
  }
};

const privateRoute = (to, from, next) => {
  if (
    process.env.VUE_APP_API_TARGET === 'development'
    || process.env.VUE_APP_API_TARGET === 'mock'
  ) return next();
  const { fullPath } = to;
  const replacePath = (_path, toIndex) => `/${_path.split('/').splice(1, toIndex - 1).toString().replace(/,/g, '/')
    .replace(/\?.*/g, '')}`;
  let selectedMenu;
  const navs = JSON.parse(store.getters['auth/menu']);
  navs.forEach((nav) => {
    if (new RegExp(`^${nav.url}$`).test(replacePath(fullPath, nav.url.split('/').length))) {
      selectedMenu = nav;
    }
    if (nav.child) {
      nav.child.forEach((navChild) => {
        if (navChild.url && new RegExp(`^${navChild.url}$`).test(replacePath(fullPath, navChild.url.split('/').length))) {
          selectedMenu = navChild;
        }
      });
    }
  });
  if (selectedMenu) return next();
  return next(store.getters['auth/defaultRoute']);
};

const authGuard = (to, from, next) => {
  if (store.getters['auth/isLoggedIn']) {
    store.dispatch('user/fetchMyInfo')
      .then((res) => {
        if (!res) {
          throw new Error('not allowed');
        }
        return next();
      })
      .catch((err) => {
        if (err.message === 'not allowed' || err.response.status === 403) {
          store.dispatch('auth/logout', 403);
        }
      });
  } else {
    next('/welcome/login');
  }
};

const routes = [
  {
    path: '/welcome',
    component: Welcome,
    beforeEnter: loginPageGuard,
    children: [
      {
        path: '',
        redirect: '/welcome/login',
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          title: 'Masuk',
        },
      },
      {
        path: 'activation-account',
        name: 'activation-account',
        component: ActivationAccount,
        meta: {
          title: 'Aktifasi Akun',
        },
      },
      {
        path: 'activation-sub-account',
        name: 'activation-sub-account',
        component: ActivationSubAccount,
        meta: {
          title: 'Aktivasi Sub Akun',
        },
      },
      {
        path: 'registration',
        name: 'registration',
        component: Registration,
        meta: {
          title: 'Registration',
        },
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
          title: 'Lupa Password',
        },
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
          title: 'Reset Password',
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPage,
        meta: {
          title: 'Ketentuan Privasi',
        },
      },
    ],
  },
  {
    path: '/main',
    component: MainContainer,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        redirect: store.getters['auth/defaultRoute'],
      },
      {
        path: 'dashboard',
        name: 'dashboard-page',
        component: DashboardPage,
        beforeEnter: privateRoute,
        meta: {
          headerTitle: 'Dashboard',
          title: 'Dashboard',
        },
      },
      {
        path: 'detail/:fromPage/:id/:cargoTitle/',
        name: 'dashboard-order-detail',
        meta: {
          headerTitle: 'Detail Pesanan',
          title: 'Detail Pesanan',
        },
        component: OrderDetail,
        beforeEnter: privateRoute,
      },
      {
        path: 'shipments',
        name: 'shipments-page',
        component: ShipmentsPage,
        beforeEnter: privateRoute,
        meta: {
          headerTitle: 'Pesanan',
          title: 'Pesanan',
        },
      },
      {
        path: 'user-admin',
        component: UserAdminPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/user-admin/list',
          },
          {
            path: 'list',
            name: 'user-admin-list-page',
            meta: {
              headerTitle: Strings.userAdmin.PAGE_NAME,
              title: 'List User Admin',
            },
            component: UserAdminList,
          },
          {
            path: 'create',
            name: 'user-admin-create-page',
            meta: {
              headerTitle: Strings.userAdmin.PAGE_NAME,
              title: 'Buat User Admin',
            },
            component: UserAdminCreate,
          },
          {
            path: 'edit/:companyId/:userId',
            name: 'user-admin-edit-page',
            meta: {
              headerTitle: Strings.userAdmin.PAGE_NAME,
              title: 'Edit User Admin',
            },
            component: UserAdminCreate,
          },

        ],
      },
      {
        path: 'company-registration',
        name: 'company-registration-page',
        component: CompanyRegistrationPage,
        beforeEnter: privateRoute,
        meta: {
          headerTitle: Strings.companyRegistration.REGISTRATION_PAGE,
          title: 'Registrasi Perusahaan',
        },
      },
      {
        path: 'registration',
        component: StatusApprovalPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            name: 'status-registration-page',
            redirect: '/main/registration/list',
          },
          {
            path: 'list',
            name: 'status-registration-list',
            component: StatusApprovalList,
            meta: {
              headerTitle: Strings.companyRegistration.REGISTRATION_PAGE,
              title: 'Registrasi',
            },
          },
          {
            path: 'company-registration',
            name: 'company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.companyRegistration.REGISTRATION_PAGE,
              title: 'Registrasi Perusahaan',
            },
          },
        ],
      },
      {
        path: 'dokumen-legal',
        component: LegalDocumentPage,
        children: [
          {
            path: '',
            redirect: '/main/dokumen-legal/list',
          },
          {
            path: 'list',
            name: 'Document Legal',
            component: LegalDocumentList,
            meta: {
              headerTitle: Strings.legalDocument.PAGE_NAME,
              title: 'Dokumen Legal',
            },
          },
        ],
      },
      {
        path: 'pesanan',
        component: OrderPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/pesanan/list/waiting',
          },
          {
            path: 'list/:status',
            name: 'order-list',
            meta: {
              headerTitle: 'Pesanan',
              title: 'List Pesanan',
            },
            component: OrderList,
          },
          {
            path: 'detail/:id/:lumpsum?',
            name: 'order-detail',
            meta: {
              headerTitle: 'Detail Pesanan',
              title: 'Detail Pesanan',
            },
            component: OrderDetail,
          },
        ],
      },
      {
        path: 'form-basp',
        name: 'form-basp',
        component: formDamageReport,
        meta: {
          headerTitle: Strings.order.DAMAGE_REPORT,
          title: 'Basp',
        },
      },
      {
        path: 'dokumen-pod',
        component: PagePodDocument,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/dokumen-pod/list',
          },
          {
            path: 'list',
            name: 'dokumen-pod-list',
            component: ListPodDocument,
            meta: {
              headerTitle: Strings.podDocument.PAGE_TITLE,
              title: 'List Dokumen POD',
            },
          },
          {
            path: 'list/:shipmentCargoId',
            name: 'dokumen-pod-list-detail',
            component: ListDetailPodDocument,
            meta: {
              headerTitle: Strings.podDocument.PAGE_TITLE,
              title: 'Detail Dokumen POD',
            },
          },
        ],
      },
      {
        path: 'approval-inbox',
        component: InboxApprovalPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/approval-inbox/list',
          },
          {
            path: 'list',
            name: 'approval-inbox',
            component: InboxApprovalList,
            meta: {
              headerTitle: Strings.approval.APPROVAL_PAGE_NAME,
              title: 'Kotak Masuk',
            },
          },
          {
            path: 'company-registration/:id/:defWorkflowId',
            name: 'inbox-company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.approval.INBOX,
              title: 'Registrasi Perusahaan',
            },
          },
          {
            path: 'document-support/:id/:defWorkflowId',
            name: 'inbox-document-support',
            component: DocumentSupportCompany,
            meta: {
              headerTitle: Strings.approval.INBOX,
              title: 'Dokumen Pendukung Perusahaan',
            },
          },
          {
            path: 'mou/:id/:defWorkflowId',
            name: 'inbox-mou',
            component: MouApproval,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Kotak Masuk (MoU)',
            },
          },
        ],
      },
      {
        path: 'approval-process',
        component: ApprovalInProcessPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/approval-process/list',
          },
          {
            path: 'list',
            name: 'approval-process',
            component: ApprovalInProcessList,
            meta: {
              headerTitle: Strings.approval.APPROVAL_PAGE_NAME,
              title: 'Dalam Proses',
            },
          },
          {
            path: 'company-registration/:id/:defWorkflowId',
            name: 'process-company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Registrasi Perusahaan',
            },
          },
          {
            path: 'document-support/:id/:defWorkflowId',
            name: 'process-document-support',
            component: DocumentSupportCompany,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Dokumen Pendukung Perusahaan',
            },
          },
          {
            path: 'mou/:id/:defWorkflowId',
            name: 'process-mou',
            component: MouApproval,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Dalam Proses (MoU)',
            },
          },
        ],
      },
      {
        path: 'complete-approval',
        component: CompletedApprovalPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/complete-approval/list',
          },
          {
            path: 'list',
            name: 'complete-approval',
            component: CompletedApprovalList,
            meta: {
              headerTitle: Strings.approval.APPROVAL_PAGE_NAME,
              title: 'Selesai',
            },
          },
          {
            path: 'company-registration/:id/:defWorkflowId',
            name: 'complete-company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Registrasi Perusahaan',
            },
          },
          {
            path: 'document-support/:id/:defWorkflowId',
            name: 'complete-document-support',
            component: DocumentSupportCompany,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Dokumen Pendukung Perusahaan',
            },
          },
          {
            path: 'mou/:id/:defWorkflowId',
            name: 'complete-mou',
            component: MouApproval,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Selesai (MoU)',
            },
          },
        ],
      },
      {
        path: 'approval/:page/:id/:defWorkflowId',
        name: 'skeleton-loaders',
        component: SkeletonLoadersWorkflow,
        meta: {
          headerTitle: '',
        },
      },
      {
        path: 'biaya-ongkir-3pl',
        component: PostageCostPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/biaya-ongkir-3pl/list',
          },
          {
            path: 'list',
            name: 'postage-cost-list',
            meta: {
              headerTitle: 'Biaya Ongkos Kirim',
              title: 'Biaya Ongkos Kirim',
            },
            component: PostageCostList,
          },
        ],
      },
      {
        path: 'driver',
        component: DriverPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/driver/list',
          },
          {
            path: 'list',
            name: 'driver-list',
            meta: {
              headerTitle: 'Sopir',
              title: 'Sopir',
            },
            component: DriverList,
          },
          {
            path: 'create',
            meta: {
              headerTitle: Strings.driver.CREATE_DRIVER_PAGE_NAME,
              title: 'Tambah Sopir',
            },
            component: DriverCreate,
          },
          {
            path: 'edit/:driverId',
            name: 'edit-driver',
            meta: {
              headerTitle: Strings.driver.EDIT_DRIVER_PAGE_NAME,
              title: 'Edit Sopir',
            },
            component: DriverCreate,
          },
          {
            path: 'detail/:driverId',
            name: 'detail-driver',
            meta: {
              headerTitle: Strings.driver.DETAIL_DRIVER,
              title: 'Detail Sopir',
            },
            component: DriverCreate,
          },
        ],
      },
      {
        path: 'invoice-data',
        component: InvoiceDataPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/invoice-data/form',
          },
          {
            path: 'form',
            name: 'Invoice Data',
            component: InvoiceDataList,
            meta: {
              headerTitle: Strings.invoiceData.pageName,
              title: 'Invoice Data',
            },
          },
        ],
      },
      {
        path: 'invoice-transporter-fixed-price',
        component: InvoiceFixedPrice,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/invoice-transporter-fixed-price/list',
          },
          {
            path: 'list',
            name: 'invoice-list-fixed-price',
            meta: {
              headerTitle: 'Fixed Price Invoice',
              title: 'List Fixed Price Invoice',
            },
            component: InvoiceFixedPriceList,
          },
          {
            path: 'list-detail/:invoiceId',
            name: 'invoice-list-detail-fixed-price',
            meta: {
              headerTitle: 'Fixed Price Invoice',
              title: 'List Detail Fixed Price Invoice',
            },
            component: InvoiceFixedPriceListDetail,
          },
          {
            path: 'create',
            name: 'create-fixed-price-invoice',
            meta: {
              headerTitle: 'Fixed Price Invoice',
              title: 'Tambah Fixed Price Invoice',
            },
            component: InvoiceCreateFixedPrice,
          },
        ],
      },
      {
        path: 'transport',
        component: TransportPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/transport/list',
          },
          {
            path: 'list',
            name: 'transport-list',
            meta: {
              headerTitle: 'Kendaraan',
              title: 'Kendaraan',
            },
            component: TransportList,
          },
          {
            path: 'create',
            name: 'transport-create',
            meta: {
              headerTitle: 'Tambah Kendaraan',
              title: 'Tambah Kendaraan',
            },
            component: TransportCreate,
          },
          {
            path: 'edit/:transportId',
            name: 'transport-edit-page',
            meta: {
              headerTitle: 'Edit Kendaraan',
              title: 'Edit Kendaraan',
            },
            component: TransportCreate,
          },
        ],
      },
      {
        path: 'about',
        component: AboutPage,
        meta: {
          headerTitle: 'Tentang Kami',
          title: 'Tentang Kami',
        },
      },
      {
        path: 'contact-us',
        component: ContactPage,
        meta: {
          headerTitle: 'Hubungi Kami',
          title: 'Hubungi Kami',
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPage,
        meta: {
          headerTitle: 'Ketentuan Privasi',
          title: 'Ketentuan Privasi',
        },
      },
      {
        path: 'email',
        component: Email,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/email/list',
          },
          {
            path: 'list',
            name: 'Email',
            component: EmailList,
            meta: {
              headerTitle: Strings.email.emailDatabase,
              title: 'Email',
            },
          },
        ],
      },
      {
        path: 'masalah',
        component: ShippingProblemPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/masalah/list/waiting',
          },
          {
            path: 'list/:status',
            name: 'masalah-list',
            meta: {
              headerTitle: 'Masalah Pengiriman',
              title: 'Masalah Pengiriman',
            },
            component: ShippingProblemList,
          },
          {
            path: 'detail/:id',
            name: 'masalah-detail',
            meta: {
              headerTitle: 'Detail Masalah Pengiriman',
              title: 'Detail Masalah Pengiriman',
            },
            component: ShippingProblemDetail,
          },
        ],
      },
      {
        path: 'kerusakan',
        component: DamageAndMissing,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/kerusakan/list',
          },
          {
            path: 'list',
            name: 'damage-and-missing-page',
            component: DamageAndMissingList,
            meta: {
              headerTitle: 'Kerusakan Dan Selisih',
              title: 'Kerusakan Dan Selisih',
            },
          },
        ],
      },
      {
        path: 'invoice-transporter',
        component: Invoice,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/invoice-transporter/list',
          },
          {
            path: 'list',
            name: 'invoice-list',
            meta: {
              headerTitle: 'Invoice',
              title: 'Invoice',
            },
            component: InvoiceList,
          },
          {
            path: 'create',
            name: 'invoice-create',
            meta: {
              headerTitle: 'Invoice',
              title: 'Tambah Invoice',
            },
            component: InvoiceCreate,
          },
          {
            path: 'edit/:invoiceId',
            name: 'invoice-edit',
            meta: {
              headerTitle: 'Ubah Invoice',
              title: 'Ubah Invoice',
            },
            component: InvoiceEdit,
          },
        ],
      },
      {
        path: 'account',
        component: AccountInfo,
        children: [
          {
            path: '',
            redirect: '/main/account/info',
          },
          {
            path: 'info',
            name: 'account-info',
            meta: {
              headerTitle: '',
              title: 'Info Akun',
            },
            component: AccountInfoGet,
          },
        ],
      },
      {
        path: 'jadwal',
        component: SchedulePage,
        beforeEnter: privateRoute,
        meta: {
          headerTitle: Strings.schedule.label.PAGE_NAME,
          title: 'Jadwal',
        },
      },
      {
        path: 'laporan',
        component: ReportPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/laporan/list',
          },
          {
            path: 'list',
            name: 'report-list',
            component: ReportList,
            meta: {
              headerTitle: 'Laporan',
              title: 'Laporan',
            },
          },
          {
            path: 'filter/:id',
            name: 'report-filter',
            meta: {
              title: 'Parameter Laporan',
            },
            component: ReportFilters,
          },
          {
            path: 'detail/:id',
            name: 'report-detail',
            meta: {
              title: 'Detail Laporan',
            },
            component: ReportDetail,
          },
        ],
      },
      {
        path: 'mou',
        component: MoUPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/mou/index',
          },
          {
            path: 'index',
            name: 'index-mou-detail',
            meta: {
              headerTitle: Strings.mou.MOU,
              title: 'Detail MoU',
            },
            component: MouDetailIndex,
          },
          {
            path: 'detail',
            name: 'mou-detail',
            meta: {
              headerTitle: Strings.mou.MOU,
              title: 'Detail MoU',
            },
            component: MoUDetail,
          },
        ],
      },
      {
        path: 'p2p-lending',
        component: PageP2PLending,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/p2p-lending/list',
          },
          {
            path: 'list',
            name: 'p2p-lending',
            component: ListP2PLending,
            meta: {
              headerTitle: Strings.p2pLending.PAGE_TITLE,
              title: 'P2P Lending',
            },
          },
          {
            path: 'create',
            name: 'create-p2p-lending',
            component: CreateP2PLending,
            meta: {
              headerTitle: Strings.p2pLending.PAGE_TITLE,
              title: 'Buat P2P Lending',
            },
          },
          {
            path: 'edit/:id',
            name: 'edit-p2p-lending',
            component: CreateP2PLending,
            meta: {
              headerTitle: Strings.p2pLending.PAGE_TITLE,
              title: 'Edit P2P Lending',
            },
          },
          {
            path: 'detail/:id',
            name: 'detail-p2p-lending',
            component: CreateP2PLending,
            meta: {
              headerTitle: Strings.p2pLending.PAGE_TITLE,
              title: 'Detail P2P Lending',
            },
          },
        ],
      },
      {
        path: 'kontrak',
        component: Contract3PLPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/kontrak/detail',
          },
          {
            path: 'detail',
            meta: {
              headerTitle: 'Kontrak',
              title: 'Detail Kontrak',
            },
            component: ContractForm,
          },
        ],
      },
      {
        path: '/*',
        redirect: store.getters['auth/defaultRoute'],
      },
    ],
  },
  {
    path: '*',
    beforeEnter: authGuard,
    redirect: '/welcome/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title || '3PL Dashboard';
  next();
});

export default router;
