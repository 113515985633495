import axios from 'axios';
import urls from './urls';
import { querystring } from '../../helper/commonHelpers';

const listP2pLending = async (filters) => {
  try {
    const res = await axios.get(`${urls.p2pLending}/filter?${querystring(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const detailP2pLending = async (id) => {
  try {
    const res = await axios.get(`${urls.p2pLending}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const documentP2pLending = async (id, detailId) => {
  try {
    const res = await axios.get(`${urls.p2pLending}/${id}/${detailId}/document`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const topP2pLending = async () => {
  try {
    const res = await axios.get(urls.topP2pLending);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const selectInvoiceP2pLending = async (filters, source) => {
  try {
    const res = await axios.get(`${urls.invoiceP2pLending}?${querystring(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createP2pLending = async (form) => {
  try {
    const res = await axios.post(urls.p2pLending, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadDocumentP2pLending = async (id, p2pDetailId, form, config) => {
  try {
    const res = await axios.post(`${urls.p2pLending}/${id}/${p2pDetailId}/upload`, form, config);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateP2pLending = async (id, form) => {
  try {
    const res = await axios.patch(`${urls.p2pLending}/${id}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteP2pLending = async (id) => {
  try {
    const res = await axios.delete(`${urls.p2pLending}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteDocumentP2pLending = async (id, detailId, documentId) => {
  try {
    const res = await axios.delete(`${urls.p2pLending}/${id}/${detailId}/document/${documentId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const submitP2pLending = async (id) => {
  try {
    const res = await axios.patch(`${urls.p2pLending}/${id}/submit`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  listP2pLending,
  detailP2pLending,
  documentP2pLending,
  topP2pLending,
  selectInvoiceP2pLending,
  createP2pLending,
  uploadDocumentP2pLending,
  updateP2pLending,
  deleteP2pLending,
  deleteDocumentP2pLending,
  submitP2pLending,
};
